const ROOT = '/'
const TIMESHEETS_LIST = '/timesheets'
const TIMESHEETS_VIEW = '/timesheets/:id'
const ADMIN_USERS_LIST = '/admin_users'
const ADMIN_USERS_ADD = '/admin_users/add'
const ADMIN_USERS_EDIT = '/admin_users/:id'
const INVOICES_LIST = '/invoices'
const INVOICE_VIEW = '/invoices/:id'
const INVOICE_SEND_EMAIL = '/invoices/:id/send_email'
const AGING_REPORT_LIST = '/aging_report'
const DRUG_TESTING_LIST = '/drug_testing'
const DEDUCTION_TYPES_ADD = '/add_deduction_types'
const BUSINESSES_LIST = '/businesses'
const SICK_PAY_HOUR_STATS = '/sick_pay_hours_stats/:id'
const SICK_PAY_HOURS_STATS_ACCRUED_SICK_HOURS = '/sick_pay_hours_stats/:id/accrued_sick_hours'
const SICK_LEAVE_TEMPLATES_ADD = '/sick_leave_templates/add'
const SICK_LEAVE_TEMPLATES_LIST = '/sick_leave_templates'
const BUSINESS_ENTITY_ADD = '/businesses/create'
const BUSINESS_ENTITY_AREAS = '/businesses/:id/areas'
const BUSINESS_ENTITY_BUSINESSES = '/businesses/:id/business'
const BUSINESS_ENTITY_DOCUMENTS_ACKNOWLEDGED = '/businesses/:id/documents/:documentId/acknowledged'
const BUSINESS_ENTITY_DOCUMENTS = '/businesses/:id/documents'
const BUSINESS_ENTITY_LOCATIONS = '/businesses/:id/locations'
const BUSINESS_ENTITY_USERS = '/businesses/:id/users'
const BUSINESS_ENTITY_INVOICES = '/businesses/:id/invoices'
const BUSINESS_ENTITY_MY_UPSHIFTERS = '/businesses/:id/my-upshifters'
const BUSINESS_ENTITY_TEMPLATES = '/businesses/:id/templates'
const BUSINESS_ENTITY_TEMPLATES_ADD = '/businesses/:id/templates/add'
const BUSINESS_ENTITY_TEMPLATES_EDIT = '/businesses/:id/templates/:templateId'
const BUSINESS_ENTITY_SHIFTS = '/businesses/:id/shifts'
const BUSINESS_ENTITY_NOTES = '/businesses/:id/notes'
const BUSINESS_ENTITY_EDIT = '/businesses/:id/edit'
const BUSINESS_ENTITY_SELECTIVE_CLAIM_MODE = '/businesses/:id/selective-claim'
const BUSINESS_ENTITY_CHILDREN_COMPANIES = '/businesses/:id/children_companies'
const BUSINESS_ENTITY_ACKNOWLEDGEMENTS = '/businesses/:id/acknowledgements'
const BUSINESS_ENTITY_INVOICE_GROUPS = '/businesses/:id/invoice-groups'
const BUSINESS_ENTITY_ADD_INVOICE_GROUP = '/businesses/:id/finance/invoice_groups/add'
const BUSINESS_ENTITY_EDIT_INVOICE_GROUP =
  '/businesses/:id/finance/invoice_groups/:invoice_group_id'
const BUSINESS_ENTITY_FINANCE = '/businesses/:id/finance'
const BUSINESS_ENTITY_BILLING_DETAILS = '/businesses/:id/billing_details'
const BUSINESS_ENTITY_REVIEWS = '/businesses/:id/reviews'
const BUSINESS_ENTITY_ATTACHMENTS = '/businesses/:id/attachments'
const BUSINESS_ENTITY_TIMESHEETS = '/businesses/:id/timesheets'
const BUSINESS_ENTITY_VIEW = '/businesses/:id'
const BUSINESS_ENTITY_SETTINGS = '/businesses/:id/settings'
const AREAS_COUNTRIES = '/areas/countries'
const AREAS_COUNTRIES_VIEW = '/areas/countries/:id'
const AREAS_STATES_VIEW = '/areas/states/:id'
const AREAS_COUNTY_VIEW = '/areas/counties/:id'
const ACTIVE_SHIFTS = '/shifts/active_shifts'
const CLOCKED_IN = '/clocked_in/:id'
const ONBOARDER_LOGIN = '/onboarder/login'
const ONBOARDER_UPSHIFTERS_VIEW = '/onboarder/upshifter_view/:id'
const ONBOARDER_UPSHIFTERS_LIST = '/onboarder/upshifter_list'
const UPSHIFTERS_VIEW = '/upshifter_view/:id'
const UPSHIFTERS_LIST = '/upshifter_list'
const BUSINESS_USERS_LIST = '/business_users_list'
const LOCATIONS_LIST = '/locations'
const LOCATIONS_ADD = '/locations/add'
const LOCATIONS_LOCATION_CATEGORIES = '/locations/location_categories'
const LOCATIONS_LOCATION_TYPES = '/locations/location_types'
const LOCATION = '/locations/:id'
const POSITION_CATEGORIZATION = '/position_categorization'
const BADGES_LIST = '/badges'
const CERTIFICATES_LIST = '/certificates'
const CERTIFICATE_ORGANIZATIONS_LIST = '/certificate_organizations'
const STRIKE_TYPES_LIST = '/strike_types'
const USER_ATTACHMENT_TYPES_LIST = '/user_attachment_types'
const PAY_RATE_SUGGESTIONS_LIST = '/pay_rate_suggestions'
const LOGOUT = '/logout'
const SHIFTS = '/shifts'
const BULK_EDIT_SHIFTS = '/shifts/bulk_edit'
const EDIT_SHIFT = '/shifts/edit/:id'
const DUPLICATE_SHIFT = '/shifts/duplicate/:id'
const FEATURED_SHIFTS_CAMPAIGNS_LIST = '/featured_shifts_campaigns'
const FEATURED_SHIFTS_CAMPAIGNS_STEP = '/featured_shifts_campaigns/create/step'
const FEATURED_SHIFTS_CAMPAIGNS_CREATE = '/featured_shifts_campaigns/create'
const FEATURED_SHIFTS_CAMPAIGNS_VIEW = '/featured_shifts_campaigns/:id'
const FEATURED_SHIFTS_CAMPAIGNS_ADD_SHIFTS = '/featured_shifts_campaigns/:id/add_shifts'
const REPORTS = '/reports'
const REPORTS_PAYROLL_REPORT = '/reports/payroll_report'
const REPORTS_AM_SUCCESS_REPORT_OLD = '/reports/weekly_am_success_report'
const REPORTS_AM_SUCCESS_REPORT = '/reports/am_success_report'
const REPORTS_MONTHLY_SALES_TAX_REPORT = '/reports/monthly_sales_tax_report'
const REPORTS_WEEKLY_EMPLOYEE_REPORT = '/reports/weekly_employee_report'
const REPORTS_CHURN_REPORT = '/reports/churn_report'
const REPORTS_FAKE_PUNCH_CARD_REPORT = '/reports/fake_punchcards_report'
const REPORTS_ACCOUNTING_REPORT = '/reports/accounting_report'
const REPORTS_GROWTH_REPORT = '/reports/growth_report'
const REPORTS_AM_SHIFTS_REPORT = '/reports/am_shifts_report'
const LOCATIONS_ADD_BUSINESS_ENTITY = '/locations/add/:id'
const REPORTS_MULTIPLE_PUNCH_CARD_REPORT = '/reports/multiple_punch_card_report'
const REPORTS_AR_BILL_REPORT = '/reports/ar_bill_report'
const REPORTS_TERMINATED_AND_REINSTATED_UPSHIFTERS_REPORT =
  '/reports/terminated_and_reinstated_upshifters_report'
const REPORTS_PAYMENT_APPLICATION_REPORT = '/reports/payment_application_report'
const REPORTS_CR_BONUS_REPORT = '/reports/cr_bonus_report'
const REPORTS_HIGH_LEVEL_STATS_REPORT = '/reports/high_level_stats_report'
const REPORTS_FOODBUY_REPORT = '/reports/foodbuy_report'
const REPORTS_RETURN_RATE_REPORT = '/reports/return_rate_report'
const REPORTS_COMPASS_APPROVAL_REPORT = '/reports/compass_approval_report'
const REPORTS_LOCATIONS_REPORT = '/reports/locations_report'
const REPORTS_UPSHIFTER_HOURS_REPORT = '/reports/upshifter_hours_report'
const REPORTS_AM_KPI_REPORT = '/reports/am_kpi_report'
const REPORTS_ACCOUNT_BREAKDOWN_REPORT = '/reports/account_breakdown_report'
const REPORTS_PROCESSED_TIMESHEET_REPORT = '/reports/processed_timesheet_report'
const PUNCHCARDS_PENDING_DELETION = '/punchcards_pending_deletion'
const EDIT_BUSINESS_USER = '/edit_business_user/:id'
const EXPENSE_TYPES = '/expense_types'
const ADD_EXPENSE_TYPE = '/expense_types/add'
const EDIT_EXPENSE_TYPE = '/expense_types/:id'
const VACCINATION_FORMS = '/vaccination_forms'
const ADD_VACCINATION_FORM = '/vaccination_forms/add/:id'
const PUNCHCARDS_PENDING_VERIFICATION = '/punchcards_pending_verification'
const FOLLOW_MANAGEMENT = '/follow_management'
const PENDING_SUSPENSION = '/pending_suspension'
const CHANGE_PASSWORD = '/change_password'
const PASSWORD_RECOVERY = '/password_recovery/:token'
const ONBOARDING_WORKFLOWS = '/onboarding_workflows'
const ADD_ONBOARDING_WORKFLOW = '/onboarding_workflows/add'
const EDIT_ONBOARDING_WORKFLOW = '/onboarding_workflows/:id'
const SHIFT_IDENTIFIER_POLICIES = '/shift_identifier_policies'
const ADD_SHIFT_IDENTIFIER_POLICY = '/shift_identifier_policies/add'
const EDIT_SHIFT_IDENTIFIER_POLICY = '/shift_identifier_policies/:id'
const REPORTS_EXPIRING_DOCUMENTS_REPORT = '/reports/expiring_documents_report'
const UNIVERSAL_TEMPLATES = '/universal_templates'
const ADD_UNIVERSAL_TEMPLATE = '/universal_templates/add'
const EDIT_UNIVERSAL_TEMPLATE = '/universal_templates/:id'
const GROUPS = '/groups'
const EDIT_GROUP = '/groups/:id'
const GAMIFICATION_SYSTEM = '/gamification_system'
const GAMIFICATION_SYSTEM_CHALLENGES_VIEW = '/gamification_system/challenges/:id'
const GAMIFICATION_SYSTEM_CHALLENGES_CREATE = '/gamification_system/challenges/create'
const POSITION_CATEGORIES = '/position_categories'
const GAMIFICATION_SYSTEM_ACHIEVEMENTS_VIEW = '/gamification_system/achievements/:id'
const GAMIFICATION_SYSTEM_ACHIEVEMENTS_CREATE = '/gamification_system/achievements/create'
const WORKER_COMPENSATION_RATES = '/worker_compensation_rates/:id'
const VENDOR_MANAGEMENT_SYSTEMS = '/vendor_management_systems'
const ADD_VENDOR_MANAGEMENT_SYSTEM = '/vendor_management_systems/add'
const EDIT_VENDOR_MANAGEMENT_SYSTEM = '/vendor_management_systems/:id'
const CERTIFICATION_REQUESTS = '/certification_requests'
const PHOTO_RECOGNITION = '/photo_recognition'

const ROUTES = {
  ROOT,
  TIMESHEETS_LIST,
  TIMESHEETS_VIEW,
  ADMIN_USERS_LIST,
  ADMIN_USERS_ADD,
  ADMIN_USERS_EDIT,
  INVOICES_LIST,
  INVOICE_VIEW,
  AGING_REPORT_LIST,
  DRUG_TESTING_LIST,
  DEDUCTION_TYPES_ADD,
  BUSINESSES_LIST,
  SICK_PAY_HOUR_STATS,
  SICK_PAY_HOURS_STATS_ACCRUED_SICK_HOURS,
  SICK_LEAVE_TEMPLATES_ADD,
  SICK_LEAVE_TEMPLATES_LIST,
  BUSINESS_ENTITY_ADD,
  BUSINESS_ENTITY_AREAS,
  BUSINESS_ENTITY_BUSINESSES,
  BUSINESS_ENTITY_DOCUMENTS_ACKNOWLEDGED,
  BUSINESS_ENTITY_DOCUMENTS,
  BUSINESS_ENTITY_LOCATIONS,
  BUSINESS_ENTITY_USERS,
  BUSINESS_ENTITY_INVOICES,
  BUSINESS_ENTITY_MY_UPSHIFTERS,
  BUSINESS_ENTITY_TEMPLATES,
  BUSINESS_ENTITY_TEMPLATES_ADD,
  BUSINESS_ENTITY_TEMPLATES_EDIT,
  BUSINESS_ENTITY_SHIFTS,
  BUSINESS_ENTITY_NOTES,
  BUSINESS_ENTITY_EDIT,
  BUSINESS_ENTITY_SELECTIVE_CLAIM_MODE,
  BUSINESS_ENTITY_CHILDREN_COMPANIES,
  BUSINESS_ENTITY_ACKNOWLEDGEMENTS,
  BUSINESS_ENTITY_INVOICE_GROUPS,
  BUSINESS_ENTITY_VIEW,
  BUSINESS_ENTITY_ADD_INVOICE_GROUP,
  BUSINESS_ENTITY_EDIT_INVOICE_GROUP,
  BUSINESS_ENTITY_FINANCE,
  BUSINESS_ENTITY_BILLING_DETAILS,
  BUSINESS_ENTITY_REVIEWS,
  BUSINESS_ENTITY_ATTACHMENTS,
  BUSINESS_ENTITY_TIMESHEETS,
  AREAS_COUNTRIES,
  AREAS_COUNTRIES_VIEW,
  AREAS_STATES_VIEW,
  AREAS_COUNTY_VIEW,
  ACTIVE_SHIFTS,
  CLOCKED_IN,
  ONBOARDER_LOGIN,
  ONBOARDER_UPSHIFTERS_VIEW,
  ONBOARDER_UPSHIFTERS_LIST,
  UPSHIFTERS_VIEW,
  UPSHIFTERS_LIST,
  BUSINESS_USERS_LIST,
  LOCATIONS_LIST,
  LOCATIONS_ADD,
  LOCATIONS_LOCATION_CATEGORIES,
  LOCATIONS_LOCATION_TYPES,
  LOCATION,
  POSITION_CATEGORIZATION,
  BADGES_LIST,
  CERTIFICATE_ORGANIZATIONS_LIST,
  STRIKE_TYPES_LIST,
  USER_ATTACHMENT_TYPES_LIST,
  PAY_RATE_SUGGESTIONS_LIST,
  LOGOUT,
  SHIFTS,
  BULK_EDIT_SHIFTS,
  EDIT_SHIFT,
  DUPLICATE_SHIFT,
  FEATURED_SHIFTS_CAMPAIGNS_LIST,
  REPORTS,
  REPORTS_PAYROLL_REPORT,
  REPORTS_AM_SUCCESS_REPORT_OLD,
  REPORTS_AM_SUCCESS_REPORT,
  REPORTS_MONTHLY_SALES_TAX_REPORT,
  REPORTS_CHURN_REPORT,
  REPORTS_FAKE_PUNCH_CARD_REPORT,
  REPORTS_GROWTH_REPORT,
  REPORTS_ACCOUNTING_REPORT,
  REPORTS_AM_SHIFTS_REPORT,
  REPORTS_WEEKLY_EMPLOYEE_REPORT,
  REPORTS_MULTIPLE_PUNCH_CARD_REPORT,
  REPORTS_AR_BILL_REPORT,
  REPORTS_TERMINATED_AND_REINSTATED_UPSHIFTERS_REPORT,
  REPORTS_PAYMENT_APPLICATION_REPORT,
  REPORTS_CR_BONUS_REPORT,
  REPORTS_HIGH_LEVEL_STATS_REPORT,
  REPORTS_FOODBUY_REPORT,
  REPORTS_COMPASS_APPROVAL_REPORT,
  REPORTS_LOCATIONS_REPORT,
  REPORTS_RETURN_RATE_REPORT,
  REPORTS_UPSHIFTER_HOURS_REPORT,
  REPORTS_AM_KPI_REPORT,
  REPORTS_ACCOUNT_BREAKDOWN_REPORT,
  REPORTS_PROCESSED_TIMESHEET_REPORT,
  INVOICE_SEND_EMAIL,
  LOCATIONS_ADD_BUSINESS_ENTITY,
  FEATURED_SHIFTS_CAMPAIGNS_STEP,
  FEATURED_SHIFTS_CAMPAIGNS_CREATE,
  FEATURED_SHIFTS_CAMPAIGNS_VIEW,
  FEATURED_SHIFTS_CAMPAIGNS_ADD_SHIFTS,
  CERTIFICATES_LIST,
  PUNCHCARDS_PENDING_DELETION,
  EDIT_BUSINESS_USER,
  EXPENSE_TYPES,
  ADD_EXPENSE_TYPE,
  EDIT_EXPENSE_TYPE,
  VACCINATION_FORMS,
  ADD_VACCINATION_FORM,
  PUNCHCARDS_PENDING_VERIFICATION,
  FOLLOW_MANAGEMENT,
  PENDING_SUSPENSION,
  CHANGE_PASSWORD,
  PASSWORD_RECOVERY,
  ONBOARDING_WORKFLOWS,
  ADD_ONBOARDING_WORKFLOW,
  EDIT_ONBOARDING_WORKFLOW,
  SHIFT_IDENTIFIER_POLICIES,
  ADD_SHIFT_IDENTIFIER_POLICY,
  EDIT_SHIFT_IDENTIFIER_POLICY,
  REPORTS_EXPIRING_DOCUMENTS_REPORT,
  UNIVERSAL_TEMPLATES,
  ADD_UNIVERSAL_TEMPLATE,
  EDIT_UNIVERSAL_TEMPLATE,
  GROUPS,
  EDIT_GROUP,
  GAMIFICATION_SYSTEM,
  GAMIFICATION_SYSTEM_CHALLENGES_VIEW,
  GAMIFICATION_SYSTEM_CHALLENGES_CREATE,
  POSITION_CATEGORIES,
  GAMIFICATION_SYSTEM_ACHIEVEMENTS_VIEW,
  GAMIFICATION_SYSTEM_ACHIEVEMENTS_CREATE,
  BUSINESS_ENTITY_SETTINGS,
  WORKER_COMPENSATION_RATES,
  VENDOR_MANAGEMENT_SYSTEMS,
  ADD_VENDOR_MANAGEMENT_SYSTEM,
  EDIT_VENDOR_MANAGEMENT_SYSTEM,
  CERTIFICATION_REQUESTS,
  PHOTO_RECOGNITION,
} as const

export default ROUTES

type TRouteKeys = keyof typeof ROUTES
type TRouteValues = typeof ROUTES[TRouteKeys]
type TRouteDynamicParams = {
  id?: number
  templateId?: number
  documentId?: number
}

const ROUTE_DYNAMIC_SEGMENTS = {
  ID: ':id',
  TEMPLATE_ID: ':templateId',
  DOCUMENT_ID: ':documentId',
}

/**
 * Resolves route by replacing route dynamic segments with specific values
 * @param route
 * @param params
 * @returns resolved route
 */
export const resolveRoute = (route: TRouteValues, dynamicParams: TRouteDynamicParams) => {
  const { id, templateId, documentId } = dynamicParams
  let resolvedRoute: string = route

  if (id) {
    resolvedRoute = resolvedRoute.replace(ROUTE_DYNAMIC_SEGMENTS.ID, id.toString())
  }

  if (templateId) {
    resolvedRoute = resolvedRoute.replace(ROUTE_DYNAMIC_SEGMENTS.TEMPLATE_ID, templateId.toString())
  }

  if (documentId) {
    resolvedRoute = resolvedRoute.replace(ROUTE_DYNAMIC_SEGMENTS.DOCUMENT_ID, documentId.toString())
  }

  return resolvedRoute
}
