export enum USERS {
  CREATE_USERS = 'CREATE_USERS',
  READ_USERS = 'READ_USERS',
  UPDATE_USERS = 'UPDATE_USERS',
  DELETE_USERS = 'DELETE_USERS',

  // OTHERS
  SICK_PAY_DAY_HOURS = 'SICK_PAY_DAY_HOURS',
  TERMINATE_NO_SHOW = 'TERMINATE_NO_SHOW',
  GET_USER_PHONE_TYPE = 'GET_USER_PHONE_TYPE',
  BLOCK_USER = 'BLOCK_USER',
}

export enum UPSHIFTER_USERS {
  CREATE_UPSHIFTER_USERS = 'CREATE_UPSHIFTER_USERS',
  READ_UPSHIFTER_USERS = 'READ_UPSHIFTER_USERS',
  UPDATE_UPSHIFTER_USERS = 'UPDATE_UPSHIFTER_USERS',
  DELETE_UPSHIFTER_USERS = 'DELETE_UPSHIFTER_USERS',
}

export enum BUSINESS_USERS {
  CREATE_BUSINESS_USERS = 'CREATE_BUSINESS_USERS',
  READ_BUSINESS_USERS = 'READ_BUSINESS_USERS',
  UPDATE_BUSINESS_USERS = 'UPDATE_BUSINESS_USERS',
  DELETE_BUSINESS_USERS = 'DELETE_BUSINESS_USERS',
}

export enum ADMIN_USERS {
  CREATE_ADMIN_USERS = 'CREATE_ADMIN_USERS',
  READ_ADMIN_USERS = 'READ_ADMIN_USERS',
  UPDATE_ADMIN_USERS = 'UPDATE_ADMIN_USERS',
  DELETE_ADMIN_USERS = 'DELETE_ADMIN_USERS',
}

export enum BUSINESS_ENTITY {
  UPDATE_PENDING_APPLICATIONS_CAP = 'UPDATE_PENDING_APPLICATIONS_CAP',
  VIEW_PENDING_APPLICATIONS_CAP = 'VIEW_CAP_PENDING',
}

export enum ATTACHMENT_TITLES {
  CREATE_ATTACHMENT_TITLES = 'CREATE_ATTACHMENT_TITLES',
  READ_ATTACHMENT_TITLES = 'READ_ATTACHMENT_TITLES',
  UPDATE_ATTACHMENT_TITLES = 'UPDATE_ATTACHMENT_TITLES',
  DELETE_ATTACHMENT_TITLES = 'DELETE_ATTACHMENT_TITLES',
}

export enum CALENDAR_VIEW {
  READ_CALENDAR_VIEW = 'READ_CALENDAR_VIEW',
  UPDATE_CALENDAR_VIEW = 'UPDATE_CALENDAR_VIEW',
}

export enum USER_ATTACHMENTS {
  CREATE_USER_ATTACHMENTS = 'CREATE_USER_ATTACHMENTS',
  READ_USER_ATTACHMENTS = 'READ_USER_ATTACHMENTS',
  UPDATE_USER_ATTACHMENTS = 'UPDATE_USER_ATTACHMENTS',
  DELETE_USER_ATTACHMENTS = 'DELETE_USER_ATTACHMENTS',
}

export enum GROUPS {
  READ_GROUPS = 'READ_GROUPS',
  UPDATE_GROUPS = 'UPDATE_GROUPS',
}

export enum NOTIFICATION_PREFERENCES {
  CREATE_NOTIFICATION_PREFERENCES = 'CREATE_NOTIFICATION_PREFERENCES',
  READ_NOTIFICATION_PREFERENCES = 'READ_NOTIFICATION_PREFERENCES',
  UPDATE_NOTIFICATION_PREFERENCES = 'UPDATE_NOTIFICATION_PREFERENCES',
  DELETE_NOTIFICATION_PREFERENCES = 'DELETE_NOTIFICATION_PREFERENCES',
}

export enum REGIONS {
  CREATE_REGIONS = 'CREATE_REGIONS',
  READ_REGIONS = 'READ_REGIONS',
  UPDATE_REGIONS = 'UPDATE_REGIONS',
  DELETE_REGIONS = 'DELETE_REGIONS',
}

export enum CERTIFICATES {
  CREATE_CERTIFICATES = 'CREATE_CERTIFICATES',
  READ_CERTIFICATES = 'READ_CERTIFICATES',
  UPDATE_CERTIFICATES = 'UPDATE_CERTIFICATES',
  DELETE_CERTIFICATES = 'DELETE_CERTIFICATES',
}

export enum POSITION_CATEGORIZATION {
  CREATE_POSITION_CATEGORIZATION = 'CREATE_POSITION_CATEGORIZATION',
  READ_POSITION_CATEGORIZATION = 'READ_POSITION_CATEGORIZATION',
  UPDATE_POSITION_CATEGORIZATION = 'UPDATE_POSITION_CATEGORIZATION',
  DELETE_POSITION_CATEGORIZATION = 'DELETE_POSITION_CATEGORIZATION',
}

export enum PROPERTY_CATEGORIES {
  CREATE_PROPERTY_CATEGORIES = 'CREATE_PROPERTY_CATEGORIES',
  READ_PROPERTY_CATEGORIES = 'READ_PROPERTY_CATEGORIES',
  UPDATE_PROPERTY_CATEGORIES = 'UPDATE_PROPERTY_CATEGORIES',
  DELETE_PROPERTY_CATEGORIES = 'DELETE_PROPERTY_CATEGORIES',
}

export enum PROPERTY_TYPES {
  CREATE_PROPERTY_TYPES = 'CREATE_PROPERTY_TYPES',
  READ_PROPERTY_TYPES = 'READ_PROPERTY_TYPES',
  UPDATE_PROPERTY_TYPES = 'UPDATE_PROPERTY_TYPES',
  DELETE_PROPERTY_TYPES = 'DELETE_PROPERTY_TYPES',
}

export enum STRIKE_TYPES {
  CREATE_STRIKE_TYPES = 'CREATE_STRIKE_TYPES',
  READ_STRIKE_TYPES = 'READ_STRIKE_TYPES',
  UPDATE_STRIKE_TYPES = 'UPDATE_STRIKE_TYPES',
  DELETE_STRIKE_TYPES = 'DELETE_STRIKE_TYPES',
}

export enum COUNTRIES {
  CREATE_COUNTRIES = 'CREATE_COUNTRIES',
  READ_COUNTRIES = 'READ_COUNTRIES',
  UPDATE_COUNTRIES = 'UPDATE_COUNTRIES',
  DELETE_COUNTRIES = 'DELETE_COUNTRIES',
}

export enum STATES {
  CREATE_STATES = 'CREATE_STATES',
  READ_STATES = 'READ_STATES',
  UPDATE_STATES = 'UPDATE_STATES',
  DELETE_STATES = 'DELETE_STATES',
}

export enum COUNTIES {
  CREATE_COUNTIES = 'CREATE_COUNTIES',
  READ_COUNTIES = 'READ_COUNTIES',
  UPDATE_COUNTIES = 'UPDATE_COUNTIES',
  DELETE_COUNTIES = 'DELETE_COUNTIES',
}

export enum CITIES {
  CREATE_CITIES = 'CREATE_CITIES',
  READ_CITIES = 'READ_CITIES',
  UPDATE_CITIES = 'UPDATE_CITIES',
  DELETE_CITIES = 'DELETE_CITIES',
}

export enum PROPERTIES {
  CREATE_PROPERTIES = 'CREATE_PROPERTIES',
  READ_PROPERTIES = 'READ_PROPERTIES',
  UPDATE_PROPERTIES = 'UPDATE_PROPERTIES',
  DELETE_PROPERTIES = 'DELETE_PROPERTIES',

  // OTHERS
  GET_ADDRESS_INFO = 'GET_ADDRESS_INFO',
}

export enum SHIFTS {
  CREATE_SHIFTS = 'CREATE_SHIFTS',
  READ_SHIFTS = 'READ_SHIFTS',
  UPDATE_SHIFTS = 'UPDATE_SHIFTS',
  DELETE_SHIFTS = 'DELETE_SHIFTS',

  // OTHERS
  DUPLICATE_SHIFT = 'DUPLICATE_SHIFT',
  MOVE_TO_DRAFT = 'MOVE_TO_DRAFT',
}

export enum GIG_DAYS {
  CREATE_GIG_DAYS = 'CREATE_GIG_DAYS',
  READ_GIG_DAYS = 'READ_GIG_DAYS',
  UPDATE_GIG_DAYS = 'UPDATE_GIG_DAYS',
  DELETE_GIG_DAYS = 'DELETE_GIG_DAYS',
}

export enum APPLICATIONS {
  CREATE_APPLICATIONS = 'CREATE_APPLICATIONS',
  READ_APPLICATIONS = 'READ_APPLICATIONS',
  UPDATE_APPLICATIONS = 'UPDATE_APPLICATIONS',
  DELETE_APPLICATIONS = 'DELETE_APPLICATIONS',

  // OTHERS
  ACCEPT_APPLICATION = 'ACCEPT_APPLICATION',
  DECLINE_APPLICATION = 'DECLINE_APPLICATION',
  CANCEL_APPLICATION = 'CANCEL_APPLICATION',
  CONFIRM_APPLICATION = 'CONFIRM_APPLICATION',
}

export enum GROUP_APPLICATIONS {
  READ_GROUP_APPLICATIONS = 'READ_GROUP_APPLICATIONS',
  UPDATE_GROUP_APPLICATIONS = 'UPDATE_GROUP_APPLICATIONS',
}

export enum STRIKES {
  CREATE_STRIKES = 'CREATE_STRIKES',
  READ_STRIKES = 'READ_STRIKES',
  UPDATE_STRIKES = 'UPDATE_STRIKES',
  DELETE_STRIKES = 'DELETE_STRIKES',
}

export enum PUNCH_CARDS {
  CREATE_PUNCH_CARDS = 'CREATE_PUNCH_CARDS',
  READ_PUNCH_CARDS = 'READ_PUNCH_CARDS',
  UPDATE_PUNCH_CARDS = 'UPDATE_PUNCH_CARDS',
  DELETE_PUNCH_CARDS = 'DELETE_PUNCH_CARDS',
  TRANSFER_PUNCH_CARDS = 'TRANSFER_PUNCH_CARDS',
}

export enum REVIEWS {
  CREATE_REVIEWS = 'CREATE_REVIEWS',
  READ_REVIEWS = 'READ_REVIEWS',
  UPDATE_REVIEWS = 'UPDATE_REVIEWS',
  DELETE_REVIEWS = 'DELETE_REVIEWS',
}

export enum INVOICES {
  CREATE_INVOICES = 'CREATE_INVOICES',
  READ_INVOICES = 'READ_INVOICES',
  UPDATE_INVOICES = 'UPDATE_INVOICES',
  DELETE_INVOICES = 'DELETE_INVOICES',

  // OTHERS
  PAY_INVOICES = 'PAY_INVOICES',
  GENERATE = 'GENERATE',
  LEDGER = 'LEDGER',
  MARK_ALL_IN_REVIEW_INVOICES_PROCESSED = 'MARK_ALL_IN_REVIEW_INVOICES_PROCESSED',
  MARK_ALL_INVOICES_AS_SENT = 'MARK_ALL_INVOICES_AS_SENT',
  STATEMENT = 'STATEMENT',
  TOTAL_OPEN_BALANCE = 'TOTAL_OPEN_BALANCE',
  SEND_MAIL = 'SEND_MAIL',
  VIEW_BILLING_DETAILS = 'VIEW_BILLING_DETAILS',
}

export enum INVOICE_EXPENSE_TYPES {
  CREATE_INVOICE_EXPENSE_TYPES = 'CREATE_INVOICE_EXPENSE_TYPES',
  READ_INVOICE_EXPENSE_TYPES = 'READ_INVOICE_EXPENSE_TYPES',
  UPDATE_INVOICE_EXPENSE_TYPES = 'UPDATE_INVOICE_EXPENSE_TYPES',
  DELETE_INVOICE_EXPENSE_TYPES = 'DELETE_INVOICE_EXPENSE_TYPES',
}

export enum TIMESHEETS {
  CREATE_TIMESHEETS = 'CREATE_TIMESHEETS',
  READ_TIMESHEETS = 'READ_TIMESHEETS',
  UPDATE_TIMESHEETS = 'UPDATE_TIMESHEETS',
  DELETE_TIMESHEETS = 'DELETE_TIMESHEETS',

  // OTHERS
  GENERATE_TIMESHEETS = 'GENERATE_TIMESHEETS',
  LOCK_TIMESHEET = 'LOCK_TIMESHEET',
  UNLOCK_TIMESHEET = 'UNLOCK_TIMESHEET',
}

export enum PAYMENTS {
  CREATE_PAYMENTS = 'CREATE_PAYMENTS',
  READ_PAYMENTS = 'READ_PAYMENTS',
  UPDATE_PAYMENTS = 'UPDATE_PAYMENTS',
  DELETE_PAYMENTS = 'DELETE_PAYMENTS',
}

export enum BLOCKS {
  CREATE_BLOCKS = 'CREATE_BLOCKS',
  READ_BLOCKS = 'READ_BLOCKS',
  UPDATE_BLOCKS = 'UPDATE_BLOCKS',
  DELETE_BLOCKS = 'DELETE_BLOCKS',
}

export enum NOTIFICATIONS {
  CREATE_NOTIFICATIONS = 'CREATE_NOTIFICATIONS',
  READ_NOTIFICATIONS = 'READ_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS',
  DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS',
}

export enum CREDIT_CARDS {
  CREATE_CREDIT_CARDS = 'CREATE_CREDIT_CARDS',
  READ_CREDIT_CARDS = 'READ_CREDIT_CARDS',
  UPDATE_CREDIT_CARDS = 'UPDATE_CREDIT_CARDS',
  DELETE_CREDIT_CARDS = 'DELETE_CREDIT_CARDS',
}

export enum BLASTS {
  CREATE_BLASTS = 'CREATE_BLASTS',
  READ_BLASTS = 'READ_BLASTS',
  UPDATE_BLASTS = 'UPDATE_BLASTS',
  DELETE_BLASTS = 'DELETE_BLASTS',
}

export enum USER_CERTIFICATES {
  CREATE_USER_CERTIFICATES = 'CREATE_USER_CERTIFICATES',
  READ_USER_CERTIFICATES = 'READ_USER_CERTIFICATES',
  UPDATE_USER_CERTIFICATES = 'UPDATE_USER_CERTIFICATES',
  DELETE_USER_CERTIFICATES = 'DELETE_USER_CERTIFICATES',
}

export enum MAPS {
  CREATE_MAPS = 'CREATE_MAPS',
  READ_MAPS = 'READ_MAPS',
  UPDATE_MAPS = 'UPDATE_MAPS',
  DELETE_MAPS = 'DELETE_MAPS',
}

export enum REPORTS {
  PAYROLL_DOWNLOAD = 'PAYROLL_DOWNLOAD',
  AGING_REPORT_DOWNLOAD = 'AGING_REPORT_DOWNLOAD',
  AGING_REPORT_SEND = 'AGING_REPORT_SEND',
  EXPIRING_ONBOARD_DOCS_DOWNLOAD = 'EXPIRING_ONBOARD_DOCS_DOWNLOAD',
  EXPIRING_ONBOARD_DOCS_SEND = 'EXPIRING_ONBOARD_DOCS_SEND',
  PAYROLL_SEND = 'PAYROLL_SEND',
  PROPERTIES_DOWNLOAD = 'PROPERTIES_DOWNLOAD',
  PROPERTIES_SEND = 'PROPERTIES_SEND',
  GROWTH_DOWNLOAD = 'GROWTH_DOWNLOAD',
  INVOICES_DOWNLOAD = 'INVOICES_DOWNLOAD',
  FINANCE_AGING_DOWNLOAD = 'FINANCE_AGING_DOWNLOAD',
  GET_STATISTICS_BY_DATE = 'GET_STATISTICS_BY_DATE',
  TIMESHEETS_DOWNLOAD = 'TIMESHEETS_DOWNLOAD',
  MULTIPLE_PUNCHCARD_REPORT = 'MULTIPLE_PUNCHCARD_REPORT',
  FAKE_PUNCHCARDS_DOWNLOAD = 'FAKE_PUNCHCARDS_DOWNLOAD',
  TERMINATED_AND_REINSTATED_UPSHIFTERS = 'TERMINATED_AND_REINSTATED_UPSHIFTERS',
  NO_SHOW_DOWNLOAD = 'NO_SHOW_DOWNLOAD',
  AM_SUCCESS_REPORT_SEND = 'AM_SUCCESS_REPORT_SEND',
  AM_SUCCESS_REPORT_DOWNLOAD = 'AM_SUCCESS_REPORT_DOWNLOAD',
  WEEKLY_EMPLOYEE_REPORT_DOWNLOAD = 'WEEKLY_EMPLOYEE_REPORT_DOWNLOAD',
  DOWNLOAD_MULTIPLE_ACKNOWLEDGED_DOCS = 'DOWNLOAD_MULTIPLE_ACKNOWLEDGED_DOCS',
  ACCOUNTING_DOWNLOAD = 'ACCOUNTING_DOWNLOAD',
  SALES_TAXES_DOWNLOAD = 'SALES_TAXES_DOWNLOAD',
  ACCOUNT_MANAGERS_SHIFTS_REPORT_DOWNLOAD = 'ACCOUNT_MANAGERS_SHIFTS_REPORT_DOWNLOAD',
  PAYMENT_APPLICATION_REPORT_DOWNLOAD = 'PAYMENT_APPLICATION_REPORT_DOWNLOAD',
  AR_BILL_REPORT = 'AR_BILL_REPORT',
  CR_BONUS_REPORT_DOWNLOAD = 'CR_BONUS_REPORT_DOWNLOAD',
  HIGH_LEVEL_STATS_REPORT = 'HIGH_LEVEL_STATS_REPORT',
  FOODBUY_REPORT = 'FOODBUY_REPORT',
  COMPASS_APPROVAL_REPORT = 'COMPASS_APPROVAL_REPORT',
  RETURN_RATE_REPORT = 'RETURN_RATE_REPORT',
  UPSHIFTER_HOURS_REPORT = 'UPSHIFTER_HOURS_REPORT',
  AM_KPI_REPORT = 'AM_KPI_REPORT',
  EXPIRING_DOCUMENTS_REPORT = 'EXPIRING_DOCUMENTS_REPORT',
  LOCATIONS_REPORT = 'LOCATIONS_REPORT',
  ACCOUNT_BREAKDOWN_REPORT = 'ACCOUNT_BREAKDOWN_REPORT',
  PROCESSED_TIMESHEET_REPORT = 'PROCESSED_TIMESHEET_REPORT',
  BUSINESSES_REPORT = 'BUSINESSES_REPORT',
}

export enum EXPERIENCES {
  READ_EXPERIENCES = 'READ_EXPERIENCES',

  // OTHER
  GIG_TYPE_CATEGORY_EXPERIENCE = 'GIG_TYPE_CATEGORY_EXPERIENCE',
}

export enum CERTIFICATE_ORGANIZATIONS {
  CREATE_CERTIFICATE_ORGANIZATIONS = 'CREATE_CERTIFICATE_ORGANIZATIONS',
  READ_CERTIFICATE_ORGANIZATIONS = 'READ_CERTIFICATE_ORGANIZATIONS',
  UPDATE_CERTIFICATE_ORGANIZATIONS = 'UPDATE_CERTIFICATE_ORGANIZATIONS',
  DELETE_CERTIFICATE_ORGANIZATIONS = 'DELETE_CERTIFICATE_ORGANIZATIONS',
}

export enum AUTO_ACCEPT_CERTIFICATES {
  CREATE_AUTO_ACCEPT_CERTIFICATES = 'CREATE_AUTO_ACCEPT_CERTIFICATES',
  READ_AUTO_ACCEPT_CERTIFICATES = 'READ_AUTO_ACCEPT_CERTIFICATES',
  UPDATE_AUTO_ACCEPT_CERTIFICATES = 'UPDATE_AUTO_ACCEPT_CERTIFICATES',
  DELETE_AUTO_ACCEPT_CERTIFICATES = 'DELETE_AUTO_ACCEPT_CERTIFICATES',
}

export enum EXPENSE_TYPES {
  CREATE_EXPENSE_TYPES = 'CREATE_EXPENSE_TYPES',
  READ_EXPENSE_TYPES = 'READ_EXPENSE_TYPES',
  UPDATE_EXPENSE_TYPES = 'UPDATE_EXPENSE_TYPES',
  DELETE_EXPENSE_TYPES = 'DELETE_EXPENSE_TYPES',
}

export enum TEMPLATES {
  CREATE_TEMPLATES = 'CREATE_TEMPLATES',
  READ_TEMPLATES = 'READ_TEMPLATES',
  UPDATE_TEMPLATES = 'UPDATE_TEMPLATES',
  DELETE_TEMPLATES = 'DELETE_TEMPLATES',
}

export enum INVOICE_GROUPS {
  CREATE_INVOICE_GROUPS = 'CREATE_INVOICE_GROUPS',
  READ_INVOICE_GROUPS = 'READ_INVOICE_GROUPS',
  UPDATE_INVOICE_GROUPS = 'UPDATE_INVOICE_GROUPS',
  DELETE_INVOICE_GROUPS = 'DELETE_INVOICE_GROUPS',
}

export enum EXPENSE_TYPE_TAXES {
  CREATE_EXPENSE_TYPE_TAXES = 'CREATE_EXPENSE_TYPE_TAXES',
  READ_EXPENSE_TYPE_TAXES = 'READ_EXPENSE_TYPE_TAXES',
  UPDATE_EXPENSE_TYPE_TAXES = 'UPDATE_EXPENSE_TYPE_TAXES',
  DELETE_EXPENSE_TYPE_TAXES = 'DELETE_EXPENSE_TYPE_TAXES',
}

export enum TIMESHEET_NOTES {
  CREATE_TIMESHEET_NOTES = 'CREATE_TIMESHEET_NOTES',
  READ_TIMESHEET_NOTES = 'READ_TIMESHEET_NOTES',
  UPDATE_TIMESHEET_NOTES = 'UPDATE_TIMESHEET_NOTES',
  DELETE_TIMESHEET_NOTES = 'DELETE_TIMESHEET_NOTES',
}

export enum CREDITS {
  CREATE_CREDITS = 'CREATE_CREDITS',
  READ_CREDITS = 'READ_CREDITS',
  UPDATE_CREDITS = 'UPDATE_CREDITS',
  DELETE_CREDITS = 'DELETE_CREDITS',
}

export enum INVOICE_NOTES {
  CREATE_INVOICE_NOTES = 'CREATE_INVOICE_NOTES',
  READ_INVOICE_NOTES = 'READ_INVOICE_NOTES',
  UPDATE_INVOICE_NOTES = 'UPDATE_INVOICE_NOTES',
  DELETE_INVOICE_NOTES = 'DELETE_INVOICE_NOTES',
}

export enum REQUEST_LOGS {
  READ_REQUEST_LOGS = 'READ_REQUEST_LOGS',

  // OTHERS
  FIND_LOG = 'FIND_LOG',
}

export enum ATTACHMENTS {
  CREATE_ATTACHMENTS = 'CREATE_ATTACHMENTS',
  READ_ATTACHMENTS = 'READ_ATTACHMENTS',
  UPDATE_ATTACHMENTS = 'UPDATE_ATTACHMENTS',
  DELETE_ATTACHMENTS = 'DELETE_ATTACHMENTS',
}

export enum NOTES {
  CREATE_NOTES = 'CREATE_NOTES',
  READ_NOTES = 'READ_NOTES',
  UPDATE_NOTES = 'UPDATE_NOTES',
  DELETE_NOTES = 'DELETE_NOTES',
}

export enum BUSINESS_NOTES {
  CREATE_BUSINESS_NOTES = 'CREATE_BUSINESS_NOTES',
  READ_BUSINESS_NOTES = 'READ_BUSINESS_NOTES',
  UPDATE_BUSINESS_NOTES = 'UPDATE_BUSINESS_NOTES',
  DELETE_BUSINESS_NOTES = 'DELETE_BUSINESS_NOTES',
}

export enum NET_OPTIONS {
  CREATE_NET_OPTIONS = 'CREATE_NET_OPTIONS',
  READ_NET_OPTIONS = 'READ_NET_OPTIONS',
  UPDATE_NET_OPTIONS = 'UPDATE_NET_OPTIONS',
  DELETE_NET_OPTIONS = 'DELETE_NET_OPTIONS',
}

export enum INDUSTRIES {
  CREATE_INDUSTRIES = 'CREATE_INDUSTRIES',
  READ_INDUSTRIES = 'READ_INDUSTRIES',
  UPDATE_INDUSTRIES = 'UPDATE_INDUSTRIES',
  DELETE_INDUSTRIES = 'DELETE_INDUSTRIES',
}

export enum BUSINESSES {
  CREATE_BUSINESSES = 'CREATE_BUSINESSES',
  READ_BUSINESSES = 'READ_BUSINESSES',
  UPDATE_BUSINESSES = 'UPDATE_BUSINESSES',
  DELETE_BUSINESSES = 'DELETE_BUSINESSES',
}

export enum BACKGROUND_CHECKS {
  CREATE_BACKGROUND_CHECKS = 'CREATE_BACKGROUND_CHECKS',
  READ_BACKGROUND_CHECKS = 'READ_BACKGROUND_CHECKS',
  UPDATE_BACKGROUND_CHECKS = 'UPDATE_BACKGROUND_CHECKS',
  DELETE_BACKGROUND_CHECKS = 'DELETE_BACKGROUND_CHECKS',
}

export enum BACKGROUND_CHECK_INVITATIONS {
  CREATE_BACKGROUND_CHECK_INVITATIONS = 'CREATE_BACKGROUND_CHECK_INVITATIONS',
  READ_BACKGROUND_CHECK_INVITATIONS = 'READ_BACKGROUND_CHECK_INVITATIONS',
  UPDATE_BACKGROUND_CHECK_INVITATIONS = 'UPDATE_BACKGROUND_CHECK_INVITATIONS',
  DELETE_BACKGROUND_CHECK_INVITATIONS = 'DELETE_BACKGROUND_CHECK_INVITATIONS',
}

export enum BACKGROUND_CHECK_REPORTS {
  CREATE_BACKGROUND_CHECK_REPORTS = 'CREATE_BACKGROUND_CHECK_REPORTS',
  READ_BACKGROUND_CHECK_REPORTS = 'READ_BACKGROUND_CHECK_REPORTS',
  UPDATE_BACKGROUND_CHECK_REPORTS = 'UPDATE_BACKGROUND_CHECK_REPORTS',
  DELETE_BACKGROUND_CHECK_REPORTS = 'DELETE_BACKGROUND_CHECK_REPORTS',
}

export enum DOCUMENTS {
  CREATE_DOCUMENTS = 'CREATE_DOCUMENTS',
  READ_DOCUMENTS = 'READ_DOCUMENTS',
  UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS',
  DELETE_DOCUMENTS = 'DELETE_DOCUMENTS',
}

export enum ACKNOWLEDGED_DOCUMENTS {
  CREATE_ACKNOWLEDGED_DOCUMENTS = 'CREATE_ACKNOWLEDGED_DOCUMENTS',
  READ_ACKNOWLEDGED_DOCUMENTS = 'READ_ACKNOWLEDGED_DOCUMENTS',
  UPDATE_ACKNOWLEDGED_DOCUMENTS = 'UPDATE_ACKNOWLEDGED_DOCUMENTS',
  DELETE_ACKNOWLEDGED_DOCUMENTS = 'DELETE_ACKNOWLEDGED_DOCUMENTS',
}

export enum VIEWED_DOCUMENTS {
  CREATE_VIEWED_DOCUMENTS = 'CREATE_VIEWED_DOCUMENTS',
  READ_VIEWED_DOCUMENTS = 'READ_VIEWED_DOCUMENTS',
  UPDATE_VIEWED_DOCUMENTS = 'UPDATE_VIEWED_DOCUMENTS',
  DELETE_VIEWED_DOCUMENTS = 'DELETE_VIEWED_DOCUMENTS',
}

export enum PAID_SICK_LEAVE_TEMPLATES {
  CREATE_PAID_SICK_LEAVE_TEMPLATES = 'CREATE_PAID_SICK_LEAVE_TEMPLATES',
  READ_PAID_SICK_LEAVE_TEMPLATES = 'READ_PAID_SICK_LEAVE_TEMPLATES',
  UPDATE_PAID_SICK_LEAVE_TEMPLATES = 'UPDATE_PAID_SICK_LEAVE_TEMPLATES',
  DELETE_PAID_SICK_LEAVE_TEMPLATES = 'DELETE_PAID_SICK_LEAVE_TEMPLATES',
}

export enum ONBOARDING_WORKFLOWS {
  CREATE_ONBOARDING_WORKFLOWS = 'CREATE_ONBOARDING_WORKFLOWS',
  READ_ONBOARDING_WORKFLOWS = 'READ_ONBOARDING_WORKFLOWS',
  UPDATE_ONBOARDING_WORKFLOWS = 'UPDATE_ONBOARDING_WORKFLOWS',
  DELETE_ONBOARDING_WORKFLOWS = 'DELETE_ONBOARDING_WORKFLOWS',
}

export enum FAVORITES {
  CREATE_FAVORITES = 'CREATE_FAVORITES',
  READ_FAVORITES = 'READ_FAVORITES',
  UPDATE_FAVORITES = 'UPDATE_FAVORITES',
  DELETE_FAVORITES = 'DELETE_FAVORITES',
}

export enum ACCRUED_SICK_HOURS {
  CREATE_ACCRUED_SICK_HOURS = 'CREATE_ACCRUED_SICK_HOURS',
  READ_ACCRUED_SICK_HOURS = 'READ_ACCRUED_SICK_HOURS',
  UPDATE_ACCRUED_SICK_HOURS = 'UPDATE_ACCRUED_SICK_HOURS',
  DELETE_ACCRUED_SICK_HOURS = 'DELETE_ACCRUED_SICK_HOURS',
}

export enum PENDING_SICK_HOURS {
  CREATE_PENDING_SICK_HOURS = 'CREATE_PENDING_SICK_HOURS',
  READ_PENDING_SICK_HOURS = 'READ_PENDING_SICK_HOURS',
  UPDATE_PENDING_SICK_HOURS = 'UPDATE_PENDING_SICK_HOURS',
  DELETE_PENDING_SICK_HOURS = 'DELETE_PENDING_SICK_HOURS',
}

export enum MINIMUM_WAGES {
  CREATE_MINIMUM_WAGES = 'CREATE_MINIMUM_WAGES',
  READ_MINIMUM_WAGES = 'READ_MINIMUM_WAGES',
  UPDATE_MINIMUM_WAGES = 'UPDATE_MINIMUM_WAGES',
  DELETE_MINIMUM_WAGES = 'DELETE_MINIMUM_WAGES',
}

export enum ONBOARD_DOCUMENT_CATEGORIES {
  CREATE_ONBOARD_DOCUMENT_CATEGORIES = 'CREATE_ONBOARD_DOCUMENT_CATEGORIES',
  READ_ONBOARD_DOCUMENT_CATEGORIES = 'READ_ONBOARD_DOCUMENT_CATEGORIES',
  UPDATE_ONBOARD_DOCUMENT_CATEGORIES = 'UPDATE_ONBOARD_DOCUMENT_CATEGORIES',
  DELETE_ONBOARD_DOCUMENT_CATEGORIES = 'DELETE_ONBOARD_DOCUMENT_CATEGORIES',
}

export enum ONBOARD_DOCUMENTS {
  CREATE_ONBOARD_DOCUMENTS = 'CREATE_ONBOARD_DOCUMENTS',
  READ_ONBOARD_DOCUMENTS = 'READ_ONBOARD_DOCUMENTS',
  UPDATE_ONBOARD_DOCUMENTS = 'UPDATE_ONBOARD_DOCUMENTS',
  DELETE_ONBOARD_DOCUMENTS = 'DELETE_ONBOARD_DOCUMENTS',
}

export enum WEEKLY_WORKING_SCHEDULES {
  CREATE_WEEKLY_WORKING_SCHEDULES = 'CREATE_WEEKLY_WORKING_SCHEDULES',
  READ_WEEKLY_WORKING_SCHEDULES = 'READ_WEEKLY_WORKING_SCHEDULES',
  UPDATE_WEEKLY_WORKING_SCHEDULES = 'UPDATE_WEEKLY_WORKING_SCHEDULES',
  DELETE_WEEKLY_WORKING_SCHEDULES = 'DELETE_WEEKLY_WORKING_SCHEDULES',
}

export enum RELIABILITY_RATINGS {
  // OTHERS
  USER_RELIABILITY_RATING = 'USER_RELIABILITY_RATING',
}

export enum SCHEDULED_UPSHIFTERS {
  READ_SCHEDULED_UPSHIFTERS = 'READ_SCHEDULED_UPSHIFTERS',
}

export enum GIG_TYPE_CATEGORIES {
  CREATE_GIG_TYPE_CATEGORIES = 'CREATE_GIG_TYPE_CATEGORIES',
  READ_GIG_TYPE_CATEGORIES = 'READ_GIG_TYPE_CATEGORIES',
  UPDATE_GIG_TYPE_CATEGORIES = 'UPDATE_GIG_TYPE_CATEGORIES',
  DELETE_GIG_TYPE_CATEGORIES = 'DELETE_GIG_TYPE_CATEGORIES',
}

export enum SCREENING_TYPES {
  CREATE_SCREENING_TYPES = 'CREATE_SCREENING_TYPES',
  READ_SCREENING_TYPES = 'READ_SCREENING_TYPES',
  UPDATE_SCREENING_TYPES = 'UPDATE_SCREENING_TYPES',
  DELETE_SCREENING_TYPES = 'DELETE_SCREENING_TYPES',
}

export enum DEDUCTION_TYPES {
  CREATE_DEDUCTION_TYPES = 'CREATE_DEDUCTION_TYPES',
  READ_DEDUCTION_TYPES = 'READ_DEDUCTION_TYPES',
  UPDATE_DEDUCTION_TYPES = 'UPDATE_DEDUCTION_TYPES',
  DELETE_DEDUCTION_TYPES = 'DELETE_DEDUCTION_TYPES',
}

export enum SCREENINGS {
  CREATE_SCREENINGS = 'CREATE_SCREENINGS',
  READ_SCREENINGS = 'READ_SCREENINGS',
  UPDATE_SCREENINGS = 'UPDATE_SCREENINGS',
  DELETE_SCREENINGS = 'DELETE_SCREENINGS',
}

export enum DEDUCTIONS {
  CREATE_DEDUCTIONS = 'CREATE_DEDUCTIONS',
  READ_DEDUCTIONS = 'READ_DEDUCTIONS',
  UPDATE_DEDUCTIONS = 'UPDATE_DEDUCTIONS',
  DELETE_DEDUCTIONS = 'DELETE_DEDUCTIONS',
}

export enum SHORT_LINKS {
  CREATE_SHORT_LINKS = 'CREATE_SHORT_LINKS',
}

export enum FEATURED_GIG_CAMPAIGNS {
  CREATE_FEATURED_GIG_CAMPAIGNS = 'CREATE_FEATURED_GIG_CAMPAIGNS',
  READ_FEATURED_GIG_CAMPAIGNS = 'READ_FEATURED_GIG_CAMPAIGNS',
  UPDATE_FEATURED_GIG_CAMPAIGNS = 'UPDATE_FEATURED_GIG_CAMPAIGNS',
  DELETE_FEATURED_GIG_CAMPAIGNS = 'DELETE_FEATURED_GIG_CAMPAIGNS',
}

export enum SUGGESTED_PAY_RATES {
  CREATE_SUGGESTED_PAY_RATES = 'CREATE_SUGGESTED_PAY_RATES',
  READ_SUGGESTED_PAY_RATES = 'READ_SUGGESTED_PAY_RATES',
  UPDATE_SUGGESTED_PAY_RATES = 'UPDATE_SUGGESTED_PAY_RATES',
  DELETE_SUGGESTED_PAY_RATES = 'DELETE_SUGGESTED_PAY_RATES',
}

export enum BILLING_TYPES {
  READ_BILLING_TYPES = 'READ_BILLING_TYPES',
}

export enum CREDIT_CATEGORY_TYPES {
  CREATE_CREDIT_CATEGORY_TYPES = 'CREATE_CREDIT_CATEGORY_TYPES',
  READ_CREDIT_CATEGORY_TYPES = 'READ_CREDIT_CATEGORY_TYPES',
  UPDATE_CREDIT_CATEGORY_TYPES = 'UPDATE_CREDIT_CATEGORY_TYPES',
  DELETE_CREDIT_CATEGORY_TYPES = 'DELETE_CREDIT_CATEGORY_TYPES',
}

export enum ACTIVITY_LOGS {
  READ_ACTIVITY_LOGS = 'READ_ACTIVITY_LOGS',
}

export enum INDUSTRY_PROPERTY_TYPES {
  CREATE_INDUSTRY_PROPERTY_TYPES = 'CREATE_INDUSTRY_PROPERTY_TYPES',
  READ_INDUSTRY_PROPERTY_TYPES = 'READ_INDUSTRY_PROPERTY_TYPES',
  UPDATE_INDUSTRY_PROPERTY_TYPES = 'UPDATE_INDUSTRY_PROPERTY_TYPES',
  DELETE_INDUSTRY_PROPERTY_TYPES = 'DELETE_INDUSTRY_PROPERTY_TYPES',
}

export enum REACTIVATION_NOTIFICATION_CONFIGURATIONS {
  READ_REACTIVATION_NOTIFICATION_CONFIGURATIONS = 'READ_REACTIVATION_NOTIFICATION_CONFIGURATIONS',
  UPDATE_REACTIVATION_NOTIFICATION_CONFIGURATIONS = 'UPDATE_REACTIVATION_NOTIFICATION_CONFIGURATIONS',
}

export enum DRUG_TESTING {
  EDIT_DRUG_TESTING = 'EDIT_DRUG_TESTING',
}

export enum BONUSES {
  CREATE_BONUSES = 'CREATE_BONUSES',
  READ_BONUSES = 'READ_BONUSES',
  UPDATE_BONUSES = 'UPDATE_BONUSES',
  DELETE_BONUSES = 'DELETE_BONUSES',
}

export enum VACCINATION_FORMS {
  CREATE_VACCINATION_FORMS = 'CREATE_VACCINATION_FORMS',
  READ_VACCINATION_FORMS = 'READ_VACCINATION_FORMS',
  UPDATE_VACCINATION_FORMS = 'UPDATE_VACCINATION_FORMS',
  DELETE_VACCINATION_FORMS = 'DELETE_VACCINATION_FORMS',
}

export enum PENDING_VERIFICATION_PUNCH_CARDS {
  CREATE_PENDING_VERIFICATION_PUNCH_CARDS = 'CREATE_PENDING_VERIFICATION_PUNCH_CARDS',
  READ_PENDING_VERIFICATION_PUNCH_CARDS = 'READ_PENDING_VERIFICATION_PUNCH_CARDS',
  UPDATE_PENDING_VERIFICATION_PUNCH_CARDS = 'UPDATE_PENDING_VERIFICATION_PUNCH_CARDS',
  DELETE_PENDING_VERIFICATION_PUNCH_CARDS = 'DELETE_PENDING_VERIFICATION_PUNCH_CARDS',
}

export enum FOLLOW {
  CREATE_FOLLOW = 'CREATE_FOLLOW',
  READ_FOLLOW = 'READ_FOLLOW',
  UPDATE_FOLLOW = 'UPDATE_FOLLOW',
  DELETE_FOLLOW = 'DELETE_FOLLOW',
}

export enum PENDING_SUSPENSION {
  CREATE_PENDING_SUSPENSION = 'CREATE_PENDING_SUSPENSION',
  READ_PENDING_SUSPENSION = 'READ_PENDING_SUSPENSION',
  UPDATE_PENDING_SUSPENSION = 'UPDATE_PENDING_SUSPENSION',
  DELETE_PENDING_SUSPENSION = 'DELETE_PENDING_SUSPENSION',
}

export enum SHIFT_IDENTIFIER_POLICIES {
  CREATE_SHIFT_IDENTIFIER_POLICIES = 'CREATE_SHIFT_IDENTIFIER_POLICIES',
  READ_SHIFT_IDENTIFIER_POLICIES = 'READ_SHIFT_IDENTIFIER_POLICIES',
  UPDATE_SHIFT_IDENTIFIER_POLICIES = 'UPDATE_SHIFT_IDENTIFIER_POLICIES',
  DELETE_SHIFT_IDENTIFIER_POLICIES = 'DELETE_SHIFT_IDENTIFIER_POLICIES',
}

export enum UNIVERSAL_TEMPLATES {
  CREATE_UNIVERSAL_TEMPLATES = 'CREATE_UNIVERSAL_TEMPLATES',
  READ_UNIVERSAL_TEMPLATES = 'READ_UNIVERSAL_TEMPLATES',
  UPDATE_UNIVERSAL_TEMPLATES = 'UPDATE_UNIVERSAL_TEMPLATES',
  DELETE_UNIVERSAL_TEMPLATES = 'DELETE_UNIVERSAL_TEMPLATES',
}

export enum GAMIFICATION_SYSTEM {
  CREATE_GAMIFICATION_SYSTEM = 'CREATE_GAMIFICATION_SYSTEM',
  READ_GAMIFICATION_SYSTEM = 'READ_GAMIFICATION_SYSTEM',
  UPDATE_GAMIFICATION_SYSTEM = 'UPDATE_GAMIFICATION_SYSTEM',
  DELETE_GAMIFICATION_SYSTEM = 'DELETE_GAMIFICATION_SYSTEM',
}

export enum POSITION_CATEGORIES {
  CREATE_POSITION_CATEGORIES = 'CREATE_POSITION_CATEGORIES',
  READ_POSITION_CATEGORIES = 'READ_POSITION_CATEGORIES',
  UPDATE_POSITION_CATEGORIES = 'UPDATE_POSITION_CATEGORIES',
}

export enum WORKER_COMPENSATION_RATE {
  CREATE_WORKER_COMPENSATION_RATE = 'CREATE_WORKER_COMPENSATION_RATE',
  READ_WORKER_COMPENSATION_RATE = 'READ_WORKER_COMPENSATION_RATE',
  UPDATE_WORKER_COMPENSATION_RATE = 'UPDATE_WORKER_COMPENSATION_RATE',
  DELETE_WORKER_COMPENSATION_RATE = 'DELETE_WORKER_COMPENSATION_RATE',
}

export enum INVITE_UPSHIFTERS {
  CREATE_INVITE_UPSHIFTERS = 'CREATE_INVITE_UPSHIFTERS',
  READ_INVITE_UPSHIFTERS = 'READ_INVITE_UPSHIFTERS',
  UPDATE_INVITE_UPSHIFTERS = 'UPDATE_INVITE_UPSHIFTERS',
  DELETE_INVITE_UPSHIFTERS = 'DELETE_INVITE_UPSHIFTERS',
}

export enum VENDOR_MANAGEMENT_SYSTEMS {
  CREATE_VENDOR_MANAGEMENT_SYSTEMS = 'CREATE_VENDOR_MANAGEMENT_SYSTEMS',
  READ_VENDOR_MANAGEMENT_SYSTEMS = 'READ_VENDOR_MANAGEMENT_SYSTEMS',
  UPDATE_VENDOR_MANAGEMENT_SYSTEMS = 'UPDATE_VENDOR_MANAGEMENT_SYSTEMS',
}

export enum CERTIFICATION_REQUESTS {
  CREATE_CERTIFICATION_REQUESTS = 'CREATE_CERTIFICATION_REQUESTS',
  READ_CERTIFICATION_REQUESTS = 'READ_CERTIFICATION_REQUESTS',
  UPDATE_CERTIFICATION_REQUESTS = 'UPDATE_CERTIFICATION_REQUESTS',
  DELETE_CERTIFICATION_REQUESTS = 'DELETE_CERTIFICATION_REQUESTS',
}

export enum AVATAR_ANALYSIS_ERRORS {
  READ_AVATAR_ANALYSIS_ERRORS = 'READ_AVATAR_ANALYSIS_ERRORS',
}
