import { action, payload } from 'ts-action'
import { IAvatarAnalysisError } from '../../models/AvatarAnalysisError'
import { IError, IPayload } from '../../models/Global'

export const AvatarAnalysisErrorsFetching = action(
  'AvatarAnalysisErrorsFetching',
  payload<boolean>()
)

export const GetAvatarAnalysisErrors = action(
  'GetAvatarAnalysisErrors',
  payload<IPayload<IAvatarAnalysisError[]>>()
)

export const GetAvatarAnalysisErrorsErrors = action(
  'GetAvatarAnalysisErrorsErrors',
  payload<IError>()
)
