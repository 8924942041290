import {
  ATTACHMENT_TITLES,
  BUSINESSES,
  CERTIFICATES,
  CERTIFICATE_ORGANIZATIONS,
  COUNTIES,
  COUNTRIES,
  DEDUCTION_TYPES,
  DRUG_TESTING,
  GAMIFICATION_SYSTEM,
  POSITION_CATEGORIZATION,
  GROUPS,
  PAID_SICK_LEAVE_TEMPLATES,
  POSITION_CATEGORIES,
  PROPERTIES,
  PROPERTY_CATEGORIES,
  PROPERTY_TYPES,
  SHIFT_IDENTIFIER_POLICIES,
  STATES,
  STRIKE_TYPES,
  SUGGESTED_PAY_RATES,
  UNIVERSAL_TEMPLATES,
  WORKER_COMPENSATION_RATE,
  VENDOR_MANAGEMENT_SYSTEMS,
  AVATAR_ANALYSIS_ERRORS,
} from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import {
  AsyncAddDeductionTypes,
  AsyncAreasCountries,
  AsyncAreasCountry,
  AsyncAreasCounty,
  AsyncAreasState,
  AsyncCertificateOrganizations,
  AsyncCertificates,
  AsyncDrugTesting,
  AsyncPositionCategorization,
  AsyncLocationCategories,
  AsyncLocationTypes,
  AsyncPayRateSuggestions,
  AsyncSickLeaveTemplates,
  AsyncSickPaydayForm,
  AsyncStrikeTypes,
  AsyncUserAttachmentTypes,
  AsyncAddVaccinationForm,
  AsyncVaccinationForms,
  AsyncShiftIdentifierPolicies,
  AsyncEditShiftIdentifierPolicy,
  AsyncOnboardingWorkflows,
  AsyncAddEditOnboardingWorkflow,
  AsyncAddShiftIdentifierPolicy,
  AsyncUniversalTemplates,
  AsyncAddEditUniversalTemplate,
  AsyncGroups,
  AsyncEditGroup,
  AsyncGamificationSystem,
  AsyncCreateEditChallenge,
  AsyncPositionCategories,
  AsyncCreateEditAchievement,
  AsyncBusinessEntitySettings,
  AsyncWorkerCompensationRates,
  AsyncVendorManagementSystems,
  AsyncAddVendorManagementSystem,
  AsyncViewVendorManagementSystem,
  AsyncPhotoRecognition,
} from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'
import { FEATURE_TOGGLE_OSHA_ENABLED } from 'config/featureToggles'

const globalSettingsRoutes = [
  <AdminRoute
    exact
    path={ROUTES.SICK_LEAVE_TEMPLATES_ADD}
    layout={AdminRouteLayout}
    component={AsyncSickPaydayForm}
    requiredPermissions={[PAID_SICK_LEAVE_TEMPLATES.CREATE_PAID_SICK_LEAVE_TEMPLATES]}
    key={ROUTES.SICK_LEAVE_TEMPLATES_ADD}
  />,
  <AdminRoute
    exact
    path={ROUTES.SICK_LEAVE_TEMPLATES_LIST}
    layout={AdminRouteLayout}
    component={AsyncSickLeaveTemplates}
    requiredPermissions={[PAID_SICK_LEAVE_TEMPLATES.READ_PAID_SICK_LEAVE_TEMPLATES]}
    key={ROUTES.SICK_LEAVE_TEMPLATES_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.DRUG_TESTING_LIST}
    layout={AdminRouteLayout}
    component={AsyncDrugTesting}
    requiredPermissions={[DRUG_TESTING.EDIT_DRUG_TESTING]}
    key={ROUTES.DRUG_TESTING_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.GAMIFICATION_SYSTEM}
    layout={AdminRouteLayout}
    component={AsyncGamificationSystem}
    requiredPermissions={[GAMIFICATION_SYSTEM.READ_GAMIFICATION_SYSTEM]}
    key={ROUTES.GAMIFICATION_SYSTEM}
  />,
  <AdminRoute
    exact
    path={ROUTES.GAMIFICATION_SYSTEM_CHALLENGES_CREATE}
    layout={AdminRouteLayout}
    component={AsyncCreateEditChallenge}
    requiredPermissions={[GAMIFICATION_SYSTEM.CREATE_GAMIFICATION_SYSTEM]}
    key={ROUTES.GAMIFICATION_SYSTEM_CHALLENGES_CREATE}
  />,
  <AdminRoute
    exact
    path={ROUTES.GAMIFICATION_SYSTEM_CHALLENGES_VIEW}
    layout={AdminRouteLayout}
    component={AsyncCreateEditChallenge}
    requiredPermissions={[GAMIFICATION_SYSTEM.UPDATE_GAMIFICATION_SYSTEM]}
    key={ROUTES.GAMIFICATION_SYSTEM_CHALLENGES_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.GAMIFICATION_SYSTEM_ACHIEVEMENTS_CREATE}
    layout={AdminRouteLayout}
    component={AsyncCreateEditAchievement}
    requiredPermissions={[GAMIFICATION_SYSTEM.CREATE_GAMIFICATION_SYSTEM]}
    key={ROUTES.GAMIFICATION_SYSTEM_ACHIEVEMENTS_CREATE}
  />,
  <AdminRoute
    exact
    path={ROUTES.GAMIFICATION_SYSTEM_ACHIEVEMENTS_VIEW}
    layout={AdminRouteLayout}
    component={AsyncCreateEditAchievement}
    requiredPermissions={[GAMIFICATION_SYSTEM.UPDATE_GAMIFICATION_SYSTEM]}
    key={ROUTES.GAMIFICATION_SYSTEM_ACHIEVEMENTS_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.AREAS_COUNTRIES}
    layout={AdminRouteLayout}
    component={AsyncAreasCountries}
    requiredPermissions={[COUNTRIES.READ_COUNTRIES]}
    key={ROUTES.AREAS_COUNTRIES}
  />,
  <AdminRoute
    exact
    path={ROUTES.AREAS_COUNTRIES_VIEW}
    layout={AdminRouteLayout}
    component={AsyncAreasCountry}
    requiredPermissions={[COUNTRIES.UPDATE_COUNTRIES]}
    key={ROUTES.AREAS_COUNTRIES_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.AREAS_STATES_VIEW}
    layout={AdminRouteLayout}
    component={AsyncAreasState}
    requiredPermissions={[STATES.READ_STATES]}
    key={ROUTES.AREAS_STATES_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.AREAS_COUNTY_VIEW}
    layout={AdminRouteLayout}
    component={AsyncAreasCounty}
    requiredPermissions={[COUNTIES.READ_COUNTIES]}
    key={ROUTES.AREAS_COUNTY_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.DEDUCTION_TYPES_ADD}
    layout={AdminRouteLayout}
    component={AsyncAddDeductionTypes}
    requiredPermissions={[DEDUCTION_TYPES.READ_DEDUCTION_TYPES]}
    key={ROUTES.DEDUCTION_TYPES_ADD}
  />,
  <AdminRoute
    exact
    path={ROUTES.POSITION_CATEGORIZATION}
    layout={AdminRouteLayout}
    component={AsyncPositionCategorization}
    requiredPermissions={[POSITION_CATEGORIZATION.READ_POSITION_CATEGORIZATION]}
    key={ROUTES.POSITION_CATEGORIZATION}
  />,
  <AdminRoute
    exact
    path={ROUTES.LOCATIONS_LOCATION_TYPES}
    layout={AdminRouteLayout}
    component={AsyncLocationTypes}
    requiredPermissions={[PROPERTIES.READ_PROPERTIES, PROPERTY_TYPES.READ_PROPERTY_TYPES]}
    key={ROUTES.LOCATIONS_LOCATION_TYPES}
  />,
  <AdminRoute
    exact
    path={ROUTES.LOCATIONS_LOCATION_CATEGORIES}
    layout={AdminRouteLayout}
    component={AsyncLocationCategories}
    requiredPermissions={[PROPERTIES.READ_PROPERTIES, PROPERTY_CATEGORIES.READ_PROPERTY_CATEGORIES]}
    key={ROUTES.LOCATIONS_LOCATION_CATEGORIES}
  />,
  <AdminRoute
    exact
    path={ROUTES.CERTIFICATES_LIST}
    layout={AdminRouteLayout}
    component={AsyncCertificates}
    requiredPermissions={[CERTIFICATES.READ_CERTIFICATES]}
    key={ROUTES.CERTIFICATES_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.STRIKE_TYPES_LIST}
    layout={AdminRouteLayout}
    component={AsyncStrikeTypes}
    requiredPermissions={[STRIKE_TYPES.READ_STRIKE_TYPES]}
    key={ROUTES.STRIKE_TYPES_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.USER_ATTACHMENT_TYPES_LIST}
    layout={AdminRouteLayout}
    component={AsyncUserAttachmentTypes}
    requiredPermissions={[ATTACHMENT_TITLES.READ_ATTACHMENT_TITLES]}
    key={ROUTES.USER_ATTACHMENT_TYPES_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.CERTIFICATE_ORGANIZATIONS_LIST}
    layout={AdminRouteLayout}
    component={AsyncCertificateOrganizations}
    requiredPermissions={[CERTIFICATE_ORGANIZATIONS.READ_CERTIFICATE_ORGANIZATIONS]}
    key={ROUTES.CERTIFICATE_ORGANIZATIONS_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.PAY_RATE_SUGGESTIONS_LIST}
    layout={AdminRouteLayout}
    component={AsyncPayRateSuggestions}
    requiredPermissions={[SUGGESTED_PAY_RATES.READ_SUGGESTED_PAY_RATES]}
    key={ROUTES.PAY_RATE_SUGGESTIONS_LIST}
  />,

  <AdminRoute
    exact
    path={ROUTES.SHIFT_IDENTIFIER_POLICIES}
    layout={AdminRouteLayout}
    component={AsyncShiftIdentifierPolicies}
    requiredPermissions={[SHIFT_IDENTIFIER_POLICIES.READ_SHIFT_IDENTIFIER_POLICIES]}
    key={ROUTES.SHIFT_IDENTIFIER_POLICIES}
  />,
  <AdminRoute
    exact
    path={ROUTES.ADD_SHIFT_IDENTIFIER_POLICY}
    layout={AdminRouteLayout}
    component={AsyncAddShiftIdentifierPolicy}
    requiredPermissions={[SHIFT_IDENTIFIER_POLICIES.CREATE_SHIFT_IDENTIFIER_POLICIES]}
    key={ROUTES.ADD_SHIFT_IDENTIFIER_POLICY}
  />,
  <AdminRoute
    exact
    path={ROUTES.EDIT_SHIFT_IDENTIFIER_POLICY}
    layout={AdminRouteLayout}
    component={AsyncEditShiftIdentifierPolicy}
    requiredPermissions={[SHIFT_IDENTIFIER_POLICIES.UPDATE_SHIFT_IDENTIFIER_POLICIES]}
    key={ROUTES.EDIT_SHIFT_IDENTIFIER_POLICY}
  />,
  <AdminRoute
    exact
    path={ROUTES.ADD_ONBOARDING_WORKFLOW}
    layout={AdminRouteLayout}
    component={AsyncAddEditOnboardingWorkflow}
    key={ROUTES.ADD_ONBOARDING_WORKFLOW}
  />,
  <AdminRoute
    exact
    path={ROUTES.EDIT_ONBOARDING_WORKFLOW}
    layout={AdminRouteLayout}
    component={AsyncAddEditOnboardingWorkflow}
    key={ROUTES.EDIT_ONBOARDING_WORKFLOW}
  />,
  <AdminRoute
    path={ROUTES.ONBOARDING_WORKFLOWS}
    layout={AdminRouteLayout}
    component={AsyncOnboardingWorkflows}
    key={ROUTES.ONBOARDING_WORKFLOWS}
  />,
  <AdminRoute
    exact
    path={ROUTES.UNIVERSAL_TEMPLATES}
    layout={AdminRouteLayout}
    component={AsyncUniversalTemplates}
    requiredPermissions={[UNIVERSAL_TEMPLATES.READ_UNIVERSAL_TEMPLATES]}
    key={ROUTES.UNIVERSAL_TEMPLATES}
  />,
  <AdminRoute
    exact
    path={ROUTES.ADD_UNIVERSAL_TEMPLATE}
    layout={AdminRouteLayout}
    component={AsyncAddEditUniversalTemplate}
    requiredPermissions={[UNIVERSAL_TEMPLATES.CREATE_UNIVERSAL_TEMPLATES]}
    key={ROUTES.ADD_UNIVERSAL_TEMPLATE}
  />,
  <AdminRoute
    exact
    path={ROUTES.EDIT_UNIVERSAL_TEMPLATE}
    layout={AdminRouteLayout}
    component={AsyncAddEditUniversalTemplate}
    requiredPermissions={[UNIVERSAL_TEMPLATES.UPDATE_UNIVERSAL_TEMPLATES]}
    key={ROUTES.EDIT_UNIVERSAL_TEMPLATE}
  />,
  <AdminRoute
    exact
    path={ROUTES.GROUPS}
    layout={AdminRouteLayout}
    component={AsyncGroups}
    requiredPermissions={[GROUPS.READ_GROUPS]}
    key={ROUTES.GROUPS}
  />,
  <AdminRoute
    exact
    path={ROUTES.EDIT_GROUP}
    layout={AdminRouteLayout}
    component={AsyncEditGroup}
    requiredPermissions={[GROUPS.READ_GROUPS, GROUPS.UPDATE_GROUPS]}
    key={ROUTES.EDIT_GROUP}
  />,
  <AdminRoute
    exact
    path={ROUTES.POSITION_CATEGORIES}
    layout={AdminRouteLayout}
    component={AsyncPositionCategories}
    requiredPermissions={[POSITION_CATEGORIES.READ_POSITION_CATEGORIES]}
    key={ROUTES.POSITION_CATEGORIES}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_SETTINGS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntitySettings}
    requiredPermissions={[BUSINESSES.UPDATE_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_SETTINGS}
  />,
  <AdminRoute
    exact
    path={ROUTES.WORKER_COMPENSATION_RATES}
    layout={AdminRouteLayout}
    component={AsyncWorkerCompensationRates}
    requiredPermissions={[WORKER_COMPENSATION_RATE.READ_WORKER_COMPENSATION_RATE]}
    key={ROUTES.WORKER_COMPENSATION_RATES}
  />,
  <AdminRoute
    exact
    path={ROUTES.VENDOR_MANAGEMENT_SYSTEMS}
    layout={AdminRouteLayout}
    component={AsyncVendorManagementSystems}
    requiredPermissions={[VENDOR_MANAGEMENT_SYSTEMS.READ_VENDOR_MANAGEMENT_SYSTEMS]}
    key={ROUTES.VENDOR_MANAGEMENT_SYSTEMS}
  />,
  <AdminRoute
    exact
    path={ROUTES.ADD_VENDOR_MANAGEMENT_SYSTEM}
    layout={AdminRouteLayout}
    component={AsyncAddVendorManagementSystem}
    requiredPermissions={[VENDOR_MANAGEMENT_SYSTEMS.CREATE_VENDOR_MANAGEMENT_SYSTEMS]}
    key={ROUTES.ADD_VENDOR_MANAGEMENT_SYSTEM}
  />,
  <AdminRoute
    exact
    path={ROUTES.EDIT_VENDOR_MANAGEMENT_SYSTEM}
    layout={AdminRouteLayout}
    component={AsyncViewVendorManagementSystem}
    requiredPermissions={[VENDOR_MANAGEMENT_SYSTEMS.UPDATE_VENDOR_MANAGEMENT_SYSTEMS]}
    key={ROUTES.EDIT_VENDOR_MANAGEMENT_SYSTEM}
  />,
  <AdminRoute
    exact
    path={ROUTES.PHOTO_RECOGNITION}
    layout={AdminRouteLayout}
    component={AsyncPhotoRecognition}
    requiredPermissions={[AVATAR_ANALYSIS_ERRORS.READ_AVATAR_ANALYSIS_ERRORS]}
    key={ROUTES.PHOTO_RECOGNITION}
  />,
]

if (FEATURE_TOGGLE_OSHA_ENABLED) {
  globalSettingsRoutes.push(
    <AdminRoute
      exact
      path={ROUTES.ADD_VACCINATION_FORM}
      layout={AdminRouteLayout}
      component={AsyncAddVaccinationForm}
      key={ROUTES.ADD_VACCINATION_FORM}
    />,
    <AdminRoute
      exact
      path={ROUTES.VACCINATION_FORMS}
      layout={AdminRouteLayout}
      component={AsyncVaccinationForms}
      key={ROUTES.VACCINATION_FORMS}
    />
  )
}

export default globalSettingsRoutes
