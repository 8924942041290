import {
  ACCRUED_SICK_HOURS,
  ACKNOWLEDGED_DOCUMENTS,
  ACTIVITY_LOGS,
  ADMIN_USERS,
  APPLICATIONS,
  ATTACHMENTS,
  ATTACHMENT_TITLES,
  AUTO_ACCEPT_CERTIFICATES,
  BACKGROUND_CHECKS,
  BACKGROUND_CHECK_INVITATIONS,
  BACKGROUND_CHECK_REPORTS,
  BILLING_TYPES,
  BLASTS,
  BLOCKS,
  BONUSES,
  BUSINESSES,
  BUSINESS_NOTES,
  BUSINESS_USERS,
  CERTIFICATES,
  CERTIFICATE_ORGANIZATIONS,
  CITIES,
  COUNTIES,
  COUNTRIES,
  CREDITS,
  CREDIT_CARDS,
  CREDIT_CATEGORY_TYPES,
  DEDUCTIONS,
  DEDUCTION_TYPES,
  DOCUMENTS,
  DRUG_TESTING,
  EXPENSE_TYPES,
  EXPENSE_TYPE_TAXES,
  EXPERIENCES,
  FAVORITES,
  FEATURED_GIG_CAMPAIGNS,
  FOLLOW,
  GAMIFICATION_SYSTEM,
  GIG_DAYS,
  POSITION_CATEGORIZATION,
  GIG_TYPE_CATEGORIES,
  GROUPS,
  GROUP_APPLICATIONS,
  INDUSTRIES,
  INDUSTRY_PROPERTY_TYPES,
  INVOICES,
  INVOICE_EXPENSE_TYPES,
  INVOICE_GROUPS,
  INVOICE_NOTES,
  MAPS,
  MINIMUM_WAGES,
  NET_OPTIONS,
  NOTES,
  NOTIFICATIONS,
  NOTIFICATION_PREFERENCES,
  ONBOARDING_WORKFLOWS,
  ONBOARD_DOCUMENTS,
  ONBOARD_DOCUMENT_CATEGORIES,
  PAID_SICK_LEAVE_TEMPLATES,
  PAYMENTS,
  PENDING_SICK_HOURS,
  PENDING_VERIFICATION_PUNCH_CARDS,
  POSITION_CATEGORIES,
  PROPERTIES,
  PROPERTY_CATEGORIES,
  PROPERTY_TYPES,
  PUNCH_CARDS,
  REACTIVATION_NOTIFICATION_CONFIGURATIONS,
  REGIONS,
  RELIABILITY_RATINGS,
  REPORTS,
  REQUEST_LOGS,
  REVIEWS,
  SCHEDULED_UPSHIFTERS,
  SCREENINGS,
  SCREENING_TYPES,
  SHIFTS,
  SHIFT_IDENTIFIER_POLICIES,
  SHORT_LINKS,
  STATES,
  STRIKES,
  STRIKE_TYPES,
  SUGGESTED_PAY_RATES,
  TEMPLATES,
  TIMESHEETS,
  TIMESHEET_NOTES,
  UPSHIFTER_USERS,
  USERS,
  USER_ATTACHMENTS,
  USER_CERTIFICATES,
  VACCINATION_FORMS,
  VIEWED_DOCUMENTS,
  WEEKLY_WORKING_SCHEDULES,
  WORKER_COMPENSATION_RATE,
  INVITE_UPSHIFTERS,
  CALENDAR_VIEW,
  VENDOR_MANAGEMENT_SYSTEMS,
  AVATAR_ANALYSIS_ERRORS,
} from 'models/Permission'

export const ACCOUNT_MANAGER_LEVEL_2 = {
  // USERS
  [USERS.CREATE_USERS]: true,
  [USERS.READ_USERS]: true,
  [USERS.UPDATE_USERS]: true,
  [USERS.DELETE_USERS]: true,

  // OTHERS [USERS]
  [USERS.SICK_PAY_DAY_HOURS]: true,
  [USERS.TERMINATE_NO_SHOW]: true,
  [USERS.GET_USER_PHONE_TYPE]: true,

  // BUSINESS USERS
  [BUSINESS_USERS.CREATE_BUSINESS_USERS]: true,
  [BUSINESS_USERS.READ_BUSINESS_USERS]: true,
  [BUSINESS_USERS.UPDATE_BUSINESS_USERS]: true,
  [BUSINESS_USERS.DELETE_BUSINESS_USERS]: true,

  // ADMIN USERS
  [ADMIN_USERS.CREATE_ADMIN_USERS]: false,
  [ADMIN_USERS.READ_ADMIN_USERS]: false,
  [ADMIN_USERS.UPDATE_ADMIN_USERS]: false,
  [ADMIN_USERS.DELETE_ADMIN_USERS]: false,

  // UPSHIFTER USERS
  [UPSHIFTER_USERS.CREATE_UPSHIFTER_USERS]: true,
  [UPSHIFTER_USERS.READ_UPSHIFTER_USERS]: true,
  [UPSHIFTER_USERS.UPDATE_UPSHIFTER_USERS]: true,
  [UPSHIFTER_USERS.DELETE_UPSHIFTER_USERS]: true,

  // ATTACHMENT_TITLES
  [ATTACHMENT_TITLES.CREATE_ATTACHMENT_TITLES]: false,
  [ATTACHMENT_TITLES.READ_ATTACHMENT_TITLES]: false,
  [ATTACHMENT_TITLES.UPDATE_ATTACHMENT_TITLES]: false,
  [ATTACHMENT_TITLES.DELETE_ATTACHMENT_TITLES]: false,

  // USER_ATTACHMENTS
  [USER_ATTACHMENTS.CREATE_USER_ATTACHMENTS]: true,
  [USER_ATTACHMENTS.READ_USER_ATTACHMENTS]: true,
  [USER_ATTACHMENTS.UPDATE_USER_ATTACHMENTS]: true,
  [USER_ATTACHMENTS.DELETE_USER_ATTACHMENTS]: true,

  // WORK_WITH_FRIENDS_GROUPS
  [GROUPS.READ_GROUPS]: true,
  [GROUPS.UPDATE_GROUPS]: true,

  // NOTIFICATION_PREFERENCES
  [NOTIFICATION_PREFERENCES.CREATE_NOTIFICATION_PREFERENCES]: true,
  [NOTIFICATION_PREFERENCES.READ_NOTIFICATION_PREFERENCES]: true,
  [NOTIFICATION_PREFERENCES.UPDATE_NOTIFICATION_PREFERENCES]: true,
  [NOTIFICATION_PREFERENCES.DELETE_NOTIFICATION_PREFERENCES]: true,

  // REGIONS
  [REGIONS.CREATE_REGIONS]: true,
  [REGIONS.READ_REGIONS]: true,
  [REGIONS.UPDATE_REGIONS]: true,
  [REGIONS.DELETE_REGIONS]: true,

  // CERTIFICATES
  [CERTIFICATES.CREATE_CERTIFICATES]: true,
  [CERTIFICATES.READ_CERTIFICATES]: true,
  [CERTIFICATES.UPDATE_CERTIFICATES]: true,
  [CERTIFICATES.DELETE_CERTIFICATES]: true,

  // POSITION_CATEGORIZATION
  [POSITION_CATEGORIZATION.CREATE_POSITION_CATEGORIZATION]: false,
  [POSITION_CATEGORIZATION.READ_POSITION_CATEGORIZATION]: false,
  [POSITION_CATEGORIZATION.UPDATE_POSITION_CATEGORIZATION]: false,
  [POSITION_CATEGORIZATION.DELETE_POSITION_CATEGORIZATION]: false,

  // PROPERTY_CATEGORIES
  [PROPERTY_CATEGORIES.CREATE_PROPERTY_CATEGORIES]: false,
  [PROPERTY_CATEGORIES.READ_PROPERTY_CATEGORIES]: false,
  [PROPERTY_CATEGORIES.UPDATE_PROPERTY_CATEGORIES]: false,
  [PROPERTY_CATEGORIES.DELETE_PROPERTY_CATEGORIES]: false,

  // PROPERTY_TYPES.
  [PROPERTY_TYPES.CREATE_PROPERTY_TYPES]: false,
  [PROPERTY_TYPES.READ_PROPERTY_TYPES]: false,
  [PROPERTY_TYPES.UPDATE_PROPERTY_TYPES]: false,
  [PROPERTY_TYPES.DELETE_PROPERTY_TYPES]: false,

  // STRIKE_TYPES
  [STRIKE_TYPES.CREATE_STRIKE_TYPES]: false,
  [STRIKE_TYPES.READ_STRIKE_TYPES]: false,
  [STRIKE_TYPES.UPDATE_STRIKE_TYPES]: false,
  [STRIKE_TYPES.DELETE_STRIKE_TYPES]: false,

  // COUNTRIES
  [COUNTRIES.CREATE_COUNTRIES]: false,
  [COUNTRIES.READ_COUNTRIES]: false,
  [COUNTRIES.UPDATE_COUNTRIES]: false,
  [COUNTRIES.DELETE_COUNTRIES]: false,

  // STATES
  [STATES.CREATE_STATES]: false,
  [STATES.READ_STATES]: true,
  [STATES.UPDATE_STATES]: false,
  [STATES.DELETE_STATES]: false,

  // COUNTIES
  [COUNTIES.CREATE_COUNTIES]: false,
  [COUNTIES.READ_COUNTIES]: true,
  [COUNTIES.UPDATE_COUNTIES]: false,
  [COUNTIES.DELETE_COUNTIES]: false,

  // CITIES
  [CITIES.CREATE_CITIES]: false,
  [CITIES.READ_CITIES]: true,
  [CITIES.UPDATE_CITIES]: false,
  [CITIES.DELETE_CITIES]: false,

  // PROPERTIES
  [PROPERTIES.CREATE_PROPERTIES]: true,
  [PROPERTIES.READ_PROPERTIES]: true,
  [PROPERTIES.UPDATE_PROPERTIES]: true,
  [PROPERTIES.DELETE_PROPERTIES]: true,

  // OTHERS
  [PROPERTIES.GET_ADDRESS_INFO]: true,

  // SHIFTS
  [SHIFTS.CREATE_SHIFTS]: true,
  [SHIFTS.READ_SHIFTS]: true,
  [SHIFTS.UPDATE_SHIFTS]: true,
  [SHIFTS.DELETE_SHIFTS]: true,

  // OTHERS
  [SHIFTS.DUPLICATE_SHIFT]: true,
  [SHIFTS.MOVE_TO_DRAFT]: true,

  // GIG_DAYS
  [GIG_DAYS.CREATE_GIG_DAYS]: true,
  [GIG_DAYS.READ_GIG_DAYS]: true,
  [GIG_DAYS.UPDATE_GIG_DAYS]: true,
  [GIG_DAYS.DELETE_GIG_DAYS]: true,

  // GROUP APPLICATIONS
  [GROUP_APPLICATIONS.READ_GROUP_APPLICATIONS]: true,
  [GROUP_APPLICATIONS.UPDATE_GROUP_APPLICATIONS]: true,

  // APPLICATIONS
  [APPLICATIONS.CREATE_APPLICATIONS]: true,
  [APPLICATIONS.READ_APPLICATIONS]: true,
  [APPLICATIONS.UPDATE_APPLICATIONS]: true,
  [APPLICATIONS.ACCEPT_APPLICATION]: true,
  [APPLICATIONS.CONFIRM_APPLICATION]: true,
  [APPLICATIONS.DECLINE_APPLICATION]: true,
  [APPLICATIONS.CANCEL_APPLICATION]: true,
  [APPLICATIONS.DELETE_APPLICATIONS]: true,

  // STRIKES
  [STRIKES.CREATE_STRIKES]: true,
  [STRIKES.READ_STRIKES]: true,
  [STRIKES.UPDATE_STRIKES]: true,
  [STRIKES.DELETE_STRIKES]: true,

  // PUNCH_CARDS
  [PUNCH_CARDS.CREATE_PUNCH_CARDS]: true,
  [PUNCH_CARDS.READ_PUNCH_CARDS]: true,
  [PUNCH_CARDS.UPDATE_PUNCH_CARDS]: true,
  [PUNCH_CARDS.DELETE_PUNCH_CARDS]: true,
  [PUNCH_CARDS.TRANSFER_PUNCH_CARDS]: true,

  // REVIEWS
  [REVIEWS.CREATE_REVIEWS]: true,
  [REVIEWS.READ_REVIEWS]: true,
  [REVIEWS.UPDATE_REVIEWS]: true,
  [REVIEWS.DELETE_REVIEWS]: true,

  // INVOICES
  [INVOICES.CREATE_INVOICES]: false,
  [INVOICES.READ_INVOICES]: true,
  [INVOICES.UPDATE_INVOICES]: false,
  [INVOICES.DELETE_INVOICES]: false,

  // OTHERS
  [INVOICES.PAY_INVOICES]: false,
  [INVOICES.GENERATE]: false,
  [INVOICES.LEDGER]: false,
  [INVOICES.MARK_ALL_IN_REVIEW_INVOICES_PROCESSED]: false,
  [INVOICES.MARK_ALL_INVOICES_AS_SENT]: false,
  [INVOICES.STATEMENT]: true,
  [INVOICES.TOTAL_OPEN_BALANCE]: true,
  [INVOICES.SEND_MAIL]: false,
  [INVOICES.VIEW_BILLING_DETAILS]: false,

  // INVOICE_EXPENSE_TYPES
  [INVOICE_EXPENSE_TYPES.CREATE_INVOICE_EXPENSE_TYPES]: true,
  [INVOICE_EXPENSE_TYPES.READ_INVOICE_EXPENSE_TYPES]: true,
  [INVOICE_EXPENSE_TYPES.UPDATE_INVOICE_EXPENSE_TYPES]: true,
  [INVOICE_EXPENSE_TYPES.DELETE_INVOICE_EXPENSE_TYPES]: false,

  // TIMESHEETS
  [TIMESHEETS.CREATE_TIMESHEETS]: true,
  [TIMESHEETS.READ_TIMESHEETS]: true,
  [TIMESHEETS.UPDATE_TIMESHEETS]: true,
  [TIMESHEETS.DELETE_TIMESHEETS]: true,

  // OTHERS
  [TIMESHEETS.GENERATE_TIMESHEETS]: true,
  [TIMESHEETS.LOCK_TIMESHEET]: true,
  [TIMESHEETS.UNLOCK_TIMESHEET]: false,

  // PAYMENTS.
  [PAYMENTS.CREATE_PAYMENTS]: true,
  [PAYMENTS.READ_PAYMENTS]: true,
  [PAYMENTS.UPDATE_PAYMENTS]: true,
  [PAYMENTS.DELETE_PAYMENTS]: true,

  // BLOCKS
  [BLOCKS.CREATE_BLOCKS]: true,
  [BLOCKS.READ_BLOCKS]: true,
  [BLOCKS.UPDATE_BLOCKS]: true,
  [BLOCKS.DELETE_BLOCKS]: true,

  // NOTIFICATIONS
  [NOTIFICATIONS.CREATE_NOTIFICATIONS]: true,
  [NOTIFICATIONS.READ_NOTIFICATIONS]: true,
  [NOTIFICATIONS.UPDATE_NOTIFICATIONS]: true,
  [NOTIFICATIONS.DELETE_NOTIFICATIONS]: true,

  // CREDIT_CARDS
  [CREDIT_CARDS.CREATE_CREDIT_CARDS]: true,
  [CREDIT_CARDS.READ_CREDIT_CARDS]: true,
  [CREDIT_CARDS.UPDATE_CREDIT_CARDS]: true,
  [CREDIT_CARDS.DELETE_CREDIT_CARDS]: true,

  // BLASTS
  [BLASTS.CREATE_BLASTS]: true,
  [BLASTS.READ_BLASTS]: true,
  [BLASTS.UPDATE_BLASTS]: true,
  [BLASTS.DELETE_BLASTS]: true,

  // USER_CERTIFICATES
  [USER_CERTIFICATES.CREATE_USER_CERTIFICATES]: true,
  [USER_CERTIFICATES.READ_USER_CERTIFICATES]: true,
  [USER_CERTIFICATES.UPDATE_USER_CERTIFICATES]: true,
  [USER_CERTIFICATES.DELETE_USER_CERTIFICATES]: true,

  // MAPS
  [MAPS.CREATE_MAPS]: true,
  [MAPS.READ_MAPS]: true,
  [MAPS.UPDATE_MAPS]: true,
  [MAPS.DELETE_MAPS]: true,

  // REPORTS
  [REPORTS.PAYROLL_DOWNLOAD]: true,
  [REPORTS.AGING_REPORT_DOWNLOAD]: true,
  [REPORTS.AGING_REPORT_SEND]: true,
  [REPORTS.EXPIRING_ONBOARD_DOCS_DOWNLOAD]: true,
  [REPORTS.EXPIRING_ONBOARD_DOCS_SEND]: true,
  [REPORTS.PAYROLL_SEND]: true,
  [REPORTS.PROPERTIES_DOWNLOAD]: true,
  [REPORTS.PROPERTIES_SEND]: true,
  [REPORTS.GROWTH_DOWNLOAD]: true,
  [REPORTS.INVOICES_DOWNLOAD]: true,
  [REPORTS.FINANCE_AGING_DOWNLOAD]: true,
  [REPORTS.GET_STATISTICS_BY_DATE]: true,
  [REPORTS.TIMESHEETS_DOWNLOAD]: true,
  [REPORTS.MULTIPLE_PUNCHCARD_REPORT]: true,
  [REPORTS.FAKE_PUNCHCARDS_DOWNLOAD]: true,
  [REPORTS.TERMINATED_AND_REINSTATED_UPSHIFTERS]: true,
  [REPORTS.NO_SHOW_DOWNLOAD]: true,
  [REPORTS.AM_SUCCESS_REPORT_SEND]: true,
  [REPORTS.AM_SUCCESS_REPORT_DOWNLOAD]: true,
  [REPORTS.WEEKLY_EMPLOYEE_REPORT_DOWNLOAD]: false,
  [REPORTS.DOWNLOAD_MULTIPLE_ACKNOWLEDGED_DOCS]: true,
  [REPORTS.ACCOUNTING_DOWNLOAD]: true,
  [REPORTS.SALES_TAXES_DOWNLOAD]: true,
  [REPORTS.ACCOUNT_MANAGERS_SHIFTS_REPORT_DOWNLOAD]: true,
  [REPORTS.PAYMENT_APPLICATION_REPORT_DOWNLOAD]: true,
  [REPORTS.AR_BILL_REPORT]: true,
  [REPORTS.HIGH_LEVEL_STATS_REPORT]: false,
  [REPORTS.RETURN_RATE_REPORT]: true,
  [REPORTS.UPSHIFTER_HOURS_REPORT]: true,
  [REPORTS.AM_KPI_REPORT]: true,
  [REPORTS.ACCOUNT_BREAKDOWN_REPORT]: true,
  [REPORTS.PROCESSED_TIMESHEET_REPORT]: true,
  [REPORTS.BUSINESSES_REPORT]: true,
  [REPORTS.FOODBUY_REPORT]: true,
  [REPORTS.COMPASS_APPROVAL_REPORT]: true,

  // EXPERIENCES
  [EXPERIENCES.READ_EXPERIENCES]: true,

  // OTHER
  [EXPERIENCES.GIG_TYPE_CATEGORY_EXPERIENCE]: true,

  // CERTIFICATE_ORGANIZATIONS
  [CERTIFICATE_ORGANIZATIONS.CREATE_CERTIFICATE_ORGANIZATIONS]: true,
  [CERTIFICATE_ORGANIZATIONS.READ_CERTIFICATE_ORGANIZATIONS]: true,
  [CERTIFICATE_ORGANIZATIONS.UPDATE_CERTIFICATE_ORGANIZATIONS]: true,
  [CERTIFICATE_ORGANIZATIONS.DELETE_CERTIFICATE_ORGANIZATIONS]: true,

  // AUTO_ACCEPT_CERTIFICATES
  [AUTO_ACCEPT_CERTIFICATES.CREATE_AUTO_ACCEPT_CERTIFICATES]: true,
  [AUTO_ACCEPT_CERTIFICATES.READ_AUTO_ACCEPT_CERTIFICATES]: true,
  [AUTO_ACCEPT_CERTIFICATES.UPDATE_AUTO_ACCEPT_CERTIFICATES]: true,
  [AUTO_ACCEPT_CERTIFICATES.DELETE_AUTO_ACCEPT_CERTIFICATES]: true,

  // EXPENSE_TYPES
  [EXPENSE_TYPES.CREATE_EXPENSE_TYPES]: false,
  [EXPENSE_TYPES.READ_EXPENSE_TYPES]: true,
  [EXPENSE_TYPES.UPDATE_EXPENSE_TYPES]: false,
  [EXPENSE_TYPES.DELETE_EXPENSE_TYPES]: false,

  // TEMPLATES
  [TEMPLATES.CREATE_TEMPLATES]: true,
  [TEMPLATES.READ_TEMPLATES]: true,
  [TEMPLATES.UPDATE_TEMPLATES]: true,
  [TEMPLATES.DELETE_TEMPLATES]: true,

  // INVOICE_GROUPS
  [INVOICE_GROUPS.CREATE_INVOICE_GROUPS]: true,
  [INVOICE_GROUPS.READ_INVOICE_GROUPS]: true,
  [INVOICE_GROUPS.UPDATE_INVOICE_GROUPS]: true,
  [INVOICE_GROUPS.DELETE_INVOICE_GROUPS]: true,

  // EXPENSE_TYPE_TAXES
  [EXPENSE_TYPE_TAXES.CREATE_EXPENSE_TYPE_TAXES]: true,
  [EXPENSE_TYPE_TAXES.READ_EXPENSE_TYPE_TAXES]: true,
  [EXPENSE_TYPE_TAXES.UPDATE_EXPENSE_TYPE_TAXES]: true,
  [EXPENSE_TYPE_TAXES.DELETE_EXPENSE_TYPE_TAXES]: false,

  // TIMESHEET_NOTES
  [TIMESHEET_NOTES.CREATE_TIMESHEET_NOTES]: true,
  [TIMESHEET_NOTES.READ_TIMESHEET_NOTES]: true,
  [TIMESHEET_NOTES.UPDATE_TIMESHEET_NOTES]: true,
  [TIMESHEET_NOTES.DELETE_TIMESHEET_NOTES]: true,

  // CREDITS
  [CREDITS.CREATE_CREDITS]: false,
  [CREDITS.READ_CREDITS]: false,
  [CREDITS.UPDATE_CREDITS]: false,
  [CREDITS.DELETE_CREDITS]: false,

  // INVOICE_NOTES
  [INVOICE_NOTES.CREATE_INVOICE_NOTES]: false,
  [INVOICE_NOTES.READ_INVOICE_NOTES]: false,
  [INVOICE_NOTES.UPDATE_INVOICE_NOTES]: false,
  [INVOICE_NOTES.DELETE_INVOICE_NOTES]: false,

  // REQUEST_LOGS
  [REQUEST_LOGS.READ_REQUEST_LOGS]: true,

  // OTHERS
  [REQUEST_LOGS.FIND_LOG]: true,

  // ATTACHMENTS
  [ATTACHMENTS.CREATE_ATTACHMENTS]: true,
  [ATTACHMENTS.READ_ATTACHMENTS]: true,
  [ATTACHMENTS.UPDATE_ATTACHMENTS]: true,
  [ATTACHMENTS.DELETE_ATTACHMENTS]: true,

  // NOTES
  [NOTES.CREATE_NOTES]: true,
  [NOTES.READ_NOTES]: true,
  [NOTES.UPDATE_NOTES]: true,
  [NOTES.DELETE_NOTES]: true,

  // NET_OPTIONS
  [NET_OPTIONS.CREATE_NET_OPTIONS]: true,
  [NET_OPTIONS.READ_NET_OPTIONS]: true,
  [NET_OPTIONS.UPDATE_NET_OPTIONS]: true,
  [NET_OPTIONS.DELETE_NET_OPTIONS]: true,

  // INDUSTRIES
  [INDUSTRIES.CREATE_INDUSTRIES]: false,
  [INDUSTRIES.READ_INDUSTRIES]: true,
  [INDUSTRIES.UPDATE_INDUSTRIES]: false,
  [INDUSTRIES.DELETE_INDUSTRIES]: false,

  // BUSINESSES
  [BUSINESSES.CREATE_BUSINESSES]: true,
  [BUSINESSES.READ_BUSINESSES]: true,
  [BUSINESSES.UPDATE_BUSINESSES]: true,
  [BUSINESSES.DELETE_BUSINESSES]: true,

  // BUSINESS_NOTES
  [BUSINESS_NOTES.CREATE_BUSINESS_NOTES]: true,
  [BUSINESS_NOTES.READ_BUSINESS_NOTES]: true,
  [BUSINESS_NOTES.UPDATE_BUSINESS_NOTES]: true,
  [BUSINESS_NOTES.DELETE_BUSINESS_NOTES]: true,

  // BACKGROUND_CHECKS
  [BACKGROUND_CHECKS.CREATE_BACKGROUND_CHECKS]: true,
  [BACKGROUND_CHECKS.READ_BACKGROUND_CHECKS]: true,
  [BACKGROUND_CHECKS.UPDATE_BACKGROUND_CHECKS]: true,
  [BACKGROUND_CHECKS.DELETE_BACKGROUND_CHECKS]: true,

  // BACKGROUND_CHECK_INVITATIONS
  [BACKGROUND_CHECK_INVITATIONS.CREATE_BACKGROUND_CHECK_INVITATIONS]: true,
  [BACKGROUND_CHECK_INVITATIONS.READ_BACKGROUND_CHECK_INVITATIONS]: true,
  [BACKGROUND_CHECK_INVITATIONS.UPDATE_BACKGROUND_CHECK_INVITATIONS]: true,
  [BACKGROUND_CHECK_INVITATIONS.DELETE_BACKGROUND_CHECK_INVITATIONS]: true,

  // BACKGROUND_CHECK_REPORTS
  [BACKGROUND_CHECK_REPORTS.CREATE_BACKGROUND_CHECK_REPORTS]: true,
  [BACKGROUND_CHECK_REPORTS.READ_BACKGROUND_CHECK_REPORTS]: true,
  [BACKGROUND_CHECK_REPORTS.UPDATE_BACKGROUND_CHECK_REPORTS]: true,
  [BACKGROUND_CHECK_REPORTS.DELETE_BACKGROUND_CHECK_REPORTS]: true,

  // DOCUMENTS
  [DOCUMENTS.CREATE_DOCUMENTS]: true,
  [DOCUMENTS.READ_DOCUMENTS]: true,
  [DOCUMENTS.UPDATE_DOCUMENTS]: true,
  [DOCUMENTS.DELETE_DOCUMENTS]: true,

  // ACKNOWLEDGED_DOCUMENTS
  [ACKNOWLEDGED_DOCUMENTS.CREATE_ACKNOWLEDGED_DOCUMENTS]: true,
  [ACKNOWLEDGED_DOCUMENTS.READ_ACKNOWLEDGED_DOCUMENTS]: true,
  [ACKNOWLEDGED_DOCUMENTS.UPDATE_ACKNOWLEDGED_DOCUMENTS]: true,
  [ACKNOWLEDGED_DOCUMENTS.DELETE_ACKNOWLEDGED_DOCUMENTS]: true,

  // VIEWED_DOCUMENTS
  [VIEWED_DOCUMENTS.CREATE_VIEWED_DOCUMENTS]: true,
  [VIEWED_DOCUMENTS.READ_VIEWED_DOCUMENTS]: true,
  [VIEWED_DOCUMENTS.UPDATE_VIEWED_DOCUMENTS]: true,
  [VIEWED_DOCUMENTS.DELETE_VIEWED_DOCUMENTS]: true,

  // PAID_SICK_LEAVE_TEMPLATES
  [PAID_SICK_LEAVE_TEMPLATES.CREATE_PAID_SICK_LEAVE_TEMPLATES]: false,
  [PAID_SICK_LEAVE_TEMPLATES.READ_PAID_SICK_LEAVE_TEMPLATES]: false,
  [PAID_SICK_LEAVE_TEMPLATES.UPDATE_PAID_SICK_LEAVE_TEMPLATES]: false,
  [PAID_SICK_LEAVE_TEMPLATES.DELETE_PAID_SICK_LEAVE_TEMPLATES]: false,

  // FAVORITES
  [FAVORITES.CREATE_FAVORITES]: true,
  [FAVORITES.READ_FAVORITES]: true,
  [FAVORITES.UPDATE_FAVORITES]: true,
  [FAVORITES.DELETE_FAVORITES]: true,

  // ACCRUED_SICK_HOURS
  [ACCRUED_SICK_HOURS.CREATE_ACCRUED_SICK_HOURS]: true,
  [ACCRUED_SICK_HOURS.READ_ACCRUED_SICK_HOURS]: true,
  [ACCRUED_SICK_HOURS.UPDATE_ACCRUED_SICK_HOURS]: true,
  [ACCRUED_SICK_HOURS.DELETE_ACCRUED_SICK_HOURS]: true,

  // PENDING_SICK_HOURS
  [PENDING_SICK_HOURS.CREATE_PENDING_SICK_HOURS]: true,
  [PENDING_SICK_HOURS.READ_PENDING_SICK_HOURS]: true,
  [PENDING_SICK_HOURS.UPDATE_PENDING_SICK_HOURS]: true,
  [PENDING_SICK_HOURS.DELETE_PENDING_SICK_HOURS]: true,

  // MINIMUM_WAGES
  [MINIMUM_WAGES.CREATE_MINIMUM_WAGES]: true,
  [MINIMUM_WAGES.READ_MINIMUM_WAGES]: true,
  [MINIMUM_WAGES.UPDATE_MINIMUM_WAGES]: true,
  [MINIMUM_WAGES.DELETE_MINIMUM_WAGES]: true,

  // ONBOARD_DOCUMENT_CATEGORIES
  [ONBOARD_DOCUMENT_CATEGORIES.CREATE_ONBOARD_DOCUMENT_CATEGORIES]: true,
  [ONBOARD_DOCUMENT_CATEGORIES.READ_ONBOARD_DOCUMENT_CATEGORIES]: true,
  [ONBOARD_DOCUMENT_CATEGORIES.UPDATE_ONBOARD_DOCUMENT_CATEGORIES]: true,
  [ONBOARD_DOCUMENT_CATEGORIES.DELETE_ONBOARD_DOCUMENT_CATEGORIES]: true,

  // ONBOARD_DOCUMENTS
  [ONBOARD_DOCUMENTS.CREATE_ONBOARD_DOCUMENTS]: true,
  [ONBOARD_DOCUMENTS.READ_ONBOARD_DOCUMENTS]: true,
  [ONBOARD_DOCUMENTS.UPDATE_ONBOARD_DOCUMENTS]: true,
  [ONBOARD_DOCUMENTS.DELETE_ONBOARD_DOCUMENTS]: true,

  // WEEKLY_WORKING_SCHEDULES
  [WEEKLY_WORKING_SCHEDULES.CREATE_WEEKLY_WORKING_SCHEDULES]: true,
  [WEEKLY_WORKING_SCHEDULES.READ_WEEKLY_WORKING_SCHEDULES]: true,
  [WEEKLY_WORKING_SCHEDULES.UPDATE_WEEKLY_WORKING_SCHEDULES]: true,
  [WEEKLY_WORKING_SCHEDULES.DELETE_WEEKLY_WORKING_SCHEDULES]: true,

  // RELIABILITY_RATINGS
  // OTHERS
  [RELIABILITY_RATINGS.USER_RELIABILITY_RATING]: true,

  // SCHEDULED_UPSHIFTERS
  [SCHEDULED_UPSHIFTERS.READ_SCHEDULED_UPSHIFTERS]: true,

  // GIG_TYPE_CATEGORIES
  [GIG_TYPE_CATEGORIES.CREATE_GIG_TYPE_CATEGORIES]: true,
  [GIG_TYPE_CATEGORIES.READ_GIG_TYPE_CATEGORIES]: true,
  [GIG_TYPE_CATEGORIES.UPDATE_GIG_TYPE_CATEGORIES]: true,
  [GIG_TYPE_CATEGORIES.DELETE_GIG_TYPE_CATEGORIES]: true,

  // SCREENING_TYPES
  [SCREENING_TYPES.CREATE_SCREENING_TYPES]: true,
  [SCREENING_TYPES.READ_SCREENING_TYPES]: true,
  [SCREENING_TYPES.UPDATE_SCREENING_TYPES]: true,
  [SCREENING_TYPES.DELETE_SCREENING_TYPES]: true,

  // DEDUCTION_TYPES
  [DEDUCTION_TYPES.CREATE_DEDUCTION_TYPES]: false,
  [DEDUCTION_TYPES.READ_DEDUCTION_TYPES]: false,
  [DEDUCTION_TYPES.UPDATE_DEDUCTION_TYPES]: false,
  [DEDUCTION_TYPES.DELETE_DEDUCTION_TYPES]: false,

  // SCREENINGS
  [SCREENINGS.CREATE_SCREENINGS]: true,
  [SCREENINGS.READ_SCREENINGS]: true,
  [SCREENINGS.UPDATE_SCREENINGS]: true,
  [SCREENINGS.DELETE_SCREENINGS]: true,

  // DEDUCTIONS
  [DEDUCTIONS.CREATE_DEDUCTIONS]: true,
  [DEDUCTIONS.READ_DEDUCTIONS]: true,
  [DEDUCTIONS.UPDATE_DEDUCTIONS]: true,
  [DEDUCTIONS.DELETE_DEDUCTIONS]: true,

  // SHORT_LINKS
  [SHORT_LINKS.CREATE_SHORT_LINKS]: true,

  // BONUSES
  [BONUSES.CREATE_BONUSES]: true,
  [BONUSES.READ_BONUSES]: true,
  [BONUSES.UPDATE_BONUSES]: true,
  [BONUSES.DELETE_BONUSES]: true,

  // FEATURED_GIG_CAMPAIGNS
  [FEATURED_GIG_CAMPAIGNS.CREATE_FEATURED_GIG_CAMPAIGNS]: true,
  [FEATURED_GIG_CAMPAIGNS.READ_FEATURED_GIG_CAMPAIGNS]: true,
  [FEATURED_GIG_CAMPAIGNS.UPDATE_FEATURED_GIG_CAMPAIGNS]: true,
  [FEATURED_GIG_CAMPAIGNS.DELETE_FEATURED_GIG_CAMPAIGNS]: true,

  // SUGGESTED_PAY_RATES
  [SUGGESTED_PAY_RATES.CREATE_SUGGESTED_PAY_RATES]: false,
  [SUGGESTED_PAY_RATES.READ_SUGGESTED_PAY_RATES]: false,
  [SUGGESTED_PAY_RATES.UPDATE_SUGGESTED_PAY_RATES]: false,
  [SUGGESTED_PAY_RATES.DELETE_SUGGESTED_PAY_RATES]: false,

  // BILLING_TYPES
  [BILLING_TYPES.READ_BILLING_TYPES]: true,

  // CREDIT_CATEGORY_TYPES
  [CREDIT_CATEGORY_TYPES.CREATE_CREDIT_CATEGORY_TYPES]: true,
  [CREDIT_CATEGORY_TYPES.READ_CREDIT_CATEGORY_TYPES]: true,
  [CREDIT_CATEGORY_TYPES.UPDATE_CREDIT_CATEGORY_TYPES]: true,
  [CREDIT_CATEGORY_TYPES.DELETE_CREDIT_CATEGORY_TYPES]: true,

  // ACTIVITY_LOGS
  [ACTIVITY_LOGS.READ_ACTIVITY_LOGS]: true,

  // INDUSTRY_PROPERTY_TYPES
  [INDUSTRY_PROPERTY_TYPES.CREATE_INDUSTRY_PROPERTY_TYPES]: true,
  [INDUSTRY_PROPERTY_TYPES.READ_INDUSTRY_PROPERTY_TYPES]: true,
  [INDUSTRY_PROPERTY_TYPES.UPDATE_INDUSTRY_PROPERTY_TYPES]: true,
  [INDUSTRY_PROPERTY_TYPES.DELETE_INDUSTRY_PROPERTY_TYPES]: true,

  // REACTIVATION_NOTIFICATION_CONFIGURATIONS
  [REACTIVATION_NOTIFICATION_CONFIGURATIONS.READ_REACTIVATION_NOTIFICATION_CONFIGURATIONS]: true,
  [REACTIVATION_NOTIFICATION_CONFIGURATIONS.UPDATE_REACTIVATION_NOTIFICATION_CONFIGURATIONS]: true,

  // DRUG TESTING
  [DRUG_TESTING.EDIT_DRUG_TESTING]: false,

  // VACCINATION_FORMS
  [VACCINATION_FORMS.CREATE_VACCINATION_FORMS]: true,
  [VACCINATION_FORMS.READ_VACCINATION_FORMS]: true,
  [VACCINATION_FORMS.UPDATE_VACCINATION_FORMS]: true,
  [VACCINATION_FORMS.DELETE_VACCINATION_FORMS]: true,

  // PENDING VERIFICATION PUNCH CARDS
  [PENDING_VERIFICATION_PUNCH_CARDS.CREATE_PENDING_VERIFICATION_PUNCH_CARDS]: true,
  [PENDING_VERIFICATION_PUNCH_CARDS.READ_PENDING_VERIFICATION_PUNCH_CARDS]: true,
  [PENDING_VERIFICATION_PUNCH_CARDS.UPDATE_PENDING_VERIFICATION_PUNCH_CARDS]: true,
  [PENDING_VERIFICATION_PUNCH_CARDS.DELETE_PENDING_VERIFICATION_PUNCH_CARDS]: true,

  // FOLLOW
  [FOLLOW.CREATE_FOLLOW]: true,
  [FOLLOW.READ_FOLLOW]: true,
  [FOLLOW.UPDATE_FOLLOW]: true,
  [FOLLOW.DELETE_FOLLOW]: true,

  // ONBOARDING WORKFLOWS
  [ONBOARDING_WORKFLOWS.CREATE_ONBOARDING_WORKFLOWS]: false,
  [ONBOARDING_WORKFLOWS.READ_ONBOARDING_WORKFLOWS]: false,
  [ONBOARDING_WORKFLOWS.UPDATE_ONBOARDING_WORKFLOWS]: false,
  [ONBOARDING_WORKFLOWS.DELETE_ONBOARDING_WORKFLOWS]: false,

  // SHIFT IDENTIFIER POLICIES
  [SHIFT_IDENTIFIER_POLICIES.CREATE_SHIFT_IDENTIFIER_POLICIES]: true,
  [SHIFT_IDENTIFIER_POLICIES.READ_SHIFT_IDENTIFIER_POLICIES]: true,
  [SHIFT_IDENTIFIER_POLICIES.UPDATE_SHIFT_IDENTIFIER_POLICIES]: true,
  [SHIFT_IDENTIFIER_POLICIES.DELETE_SHIFT_IDENTIFIER_POLICIES]: true,

  //GAMIFICATION SYSTEM
  [GAMIFICATION_SYSTEM.CREATE_GAMIFICATION_SYSTEM]: false,
  [GAMIFICATION_SYSTEM.READ_GAMIFICATION_SYSTEM]: false,
  [GAMIFICATION_SYSTEM.UPDATE_GAMIFICATION_SYSTEM]: false,
  [GAMIFICATION_SYSTEM.DELETE_GAMIFICATION_SYSTEM]: false,

  // POSITION CATEGORIES
  [POSITION_CATEGORIES.CREATE_POSITION_CATEGORIES]: false,
  [POSITION_CATEGORIES.READ_POSITION_CATEGORIES]: false,
  [POSITION_CATEGORIES.UPDATE_POSITION_CATEGORIES]: false,

  // WORKER COMPENSATION RATE
  [WORKER_COMPENSATION_RATE.CREATE_WORKER_COMPENSATION_RATE]: true,
  [WORKER_COMPENSATION_RATE.READ_WORKER_COMPENSATION_RATE]: true,
  [WORKER_COMPENSATION_RATE.UPDATE_WORKER_COMPENSATION_RATE]: true,
  [WORKER_COMPENSATION_RATE.DELETE_WORKER_COMPENSATION_RATE]: true,

  // INVITE UPSHIFTERS
  [INVITE_UPSHIFTERS.CREATE_INVITE_UPSHIFTERS]: false,
  [INVITE_UPSHIFTERS.READ_INVITE_UPSHIFTERS]: false,
  [INVITE_UPSHIFTERS.UPDATE_INVITE_UPSHIFTERS]: false,
  [INVITE_UPSHIFTERS.DELETE_INVITE_UPSHIFTERS]: false,

  // CALENDAR VIEW
  [CALENDAR_VIEW.READ_CALENDAR_VIEW]: false,
  [CALENDAR_VIEW.UPDATE_CALENDAR_VIEW]: false,

  // VENDOR MANAGEMENT SYSTEMS
  [VENDOR_MANAGEMENT_SYSTEMS.CREATE_VENDOR_MANAGEMENT_SYSTEMS]: true,
  [VENDOR_MANAGEMENT_SYSTEMS.READ_VENDOR_MANAGEMENT_SYSTEMS]: true,
  [VENDOR_MANAGEMENT_SYSTEMS.UPDATE_VENDOR_MANAGEMENT_SYSTEMS]: true,

  // AVATAR ANALYSIS ERRORS
  [AVATAR_ANALYSIS_ERRORS.READ_AVATAR_ANALYSIS_ERRORS]: false,
}
