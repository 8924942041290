import {
  faBars,
  faBringFront,
  faBuilding,
  faChartLine,
  faCog,
  faCompass,
  faCreditCardFront,
  faGlobeAmericas,
  faLayerGroup,
  faSyringe,
  faUser,
  faUserCircle,
  faUserFriends,
  faCity,
  faFileSpreadsheet,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Drawer,
  MenuItem,
  SidebarMenu,
  SidebarMenuProvider,
  SubMenu,
} from '@pinchenterprisesnpm/friday-ui'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { selectAuthIsAuth, selectAuthUserRole } from 'data/Auth/selectors'
import { isAdminUserByRoleName } from 'helpers/authHelpers'
import {
  FEATURE_TOGGLE_ADMIN_FOLLOW_SHIFTS,
  FEATURE_TOGGLE_IN_APP_ONBOARDING,
  FEATURE_TOGGLE_SHIFT_IDENTIFIER,
  FEATURE_TOGGLE_PENDING_TERMINATIONS,
  FEATURE_TOGGLE_UNIVERSAL_TEMPLATES,
  FEATURE_TOGGLE_OSHA_ENABLED,
  FEATURE_TOGGLE_WORK_WITH_FRIENDS,
  FEATURE_TOGGLE_GAMIFICATION_SYSTEM,
  FEATURE_TOGGLE_VENDOR_MANAGEMENT_SYSTEMS,
} from 'config/featureToggles'
import { checkPermissions } from 'helpers/permissionsHelpers/permissionsChecker'
import {
  ADMIN_USERS,
  ATTACHMENT_TITLES,
  BUSINESSES,
  BUSINESS_USERS,
  CERTIFICATES,
  CERTIFICATE_ORGANIZATIONS,
  COUNTRIES,
  DEDUCTION_TYPES,
  DRUG_TESTING,
  EXPENSE_TYPES,
  FEATURED_GIG_CAMPAIGNS,
  FOLLOW,
  GAMIFICATION_SYSTEM,
  POSITION_CATEGORIZATION,
  GROUPS,
  INVOICES,
  ONBOARDING_WORKFLOWS,
  PAID_SICK_LEAVE_TEMPLATES,
  PENDING_SUSPENSION,
  PENDING_VERIFICATION_PUNCH_CARDS,
  POSITION_CATEGORIES,
  PROPERTIES,
  PROPERTY_CATEGORIES,
  PROPERTY_TYPES,
  PUNCH_CARDS,
  REPORTS,
  SHIFTS,
  SHIFT_IDENTIFIER_POLICIES,
  STRIKE_TYPES,
  SUGGESTED_PAY_RATES,
  TIMESHEETS,
  UNIVERSAL_TEMPLATES,
  UPSHIFTER_USERS,
  USER_ATTACHMENTS,
  VACCINATION_FORMS,
  VENDOR_MANAGEMENT_SYSTEMS,
  CERTIFICATION_REQUESTS,
  AVATAR_ANALYSIS_ERRORS,
} from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import { AddPunchCardMenuItem } from '../../AdminRouteLayout/style'
import AddExtraPunchCardModal from '../../FinanceModule/AddExtraPunchCardModal'
import { PendingSuspensionText } from '../MenuDesktop/style'
import { MenuItemVms } from '../style'
import { Content } from './components'
import { MenuHeader, MenuHeaderLeft, MenuHeaderRight, MobileMenuWrapper, StyledMenu } from './style'
import { dispatchLogoutConfirmationModal } from '../utils'
import { canAccessPhotoRecognitionPage } from 'helpers/helperFunctions'

const MenuMobile = () => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const isAuth = useSelector(selectAuthIsAuth, shallowEqual)
  const userRole = useSelector(selectAuthUserRole, shallowEqual)

  const checkRoutePermissions = (requiredPermissions: string[], method?: 'all' | 'any') => {
    let permitted = isAuth && isAdminUserByRoleName(userRole)
    if (requiredPermissions) {
      permitted = permitted && checkPermissions(requiredPermissions, userRole, method)
    }

    return permitted
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        type='dark'
        icon={<FontAwesomeIcon icon={faBars} size='lg' />}
      />
      <Drawer
        isOpen={isOpen}
        placement='left'
        closeDrawer={() => setIsOpen(false)}
        width='280px'
        closeMethod='default'
      >
        {({ closeModal: closeDrawer }: { isOpen: boolean; closeModal: any }) => {
          const onMenuItemClick = ({ value }: { value: string }) => {
            if (value === ROUTES.LOGOUT) {
              dispatchLogoutConfirmationModal()
            } else closeDrawer()
          }
          return (
            <Content closeModal={closeDrawer}>
              <MenuHeader>
                <MenuHeaderLeft>
                  <Button
                    onClick={closeDrawer}
                    type='dark'
                    icon={<FontAwesomeIcon icon={faBars} size='lg' />}
                  />
                </MenuHeaderLeft>
                <MenuHeaderRight>Upshift</MenuHeaderRight>
              </MenuHeader>
              <MobileMenuWrapper>
                <SidebarMenuProvider>
                  <SidebarMenu
                    selectedItem={location.pathname}
                    onClick={onMenuItemClick}
                    wrapper={StyledMenu}
                    isCollapsible={false}
                  >
                    {checkRoutePermissions([SHIFTS.READ_SHIFTS]) && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={faLayerGroup} size='lg' />}
                        value={ROUTES.SHIFTS}
                        text='Shifts'
                      >
                        Shifts
                      </MenuItem>
                    )}
                    {checkRoutePermissions(
                      [
                        TIMESHEETS.READ_TIMESHEETS,
                        INVOICES.READ_INVOICES,
                        REPORTS.AGING_REPORT_DOWNLOAD,
                        PUNCH_CARDS.READ_PUNCH_CARDS,
                        PUNCH_CARDS.UPDATE_PUNCH_CARDS,
                        EXPENSE_TYPES.READ_EXPENSE_TYPES,
                        PENDING_VERIFICATION_PUNCH_CARDS.READ_PENDING_VERIFICATION_PUNCH_CARDS,
                      ],
                      'any'
                    ) && (
                      <SubMenu
                        icon={<FontAwesomeIcon icon={faCreditCardFront} size='lg' />}
                        text={'Finance'}
                        value={ROUTES.INVOICES_LIST}
                      >
                        {checkRoutePermissions([PUNCH_CARDS.CREATE_PUNCH_CARDS]) && (
                          <AddExtraPunchCardModal
                            render={(toggleModal, rest) => (
                              <AddPunchCardMenuItem
                                {...rest}
                                isNavLink={false}
                                onClick={toggleModal}
                                isMenuCollapsed={false}
                              >
                                Add Punch Card
                              </AddPunchCardMenuItem>
                            )}
                          />
                        )}
                        {checkRoutePermissions([REPORTS.AGING_REPORT_DOWNLOAD]) && (
                          <MenuItem value={ROUTES.AGING_REPORT_LIST}>Aging Report</MenuItem>
                        )}
                        {checkRoutePermissions([
                          PENDING_VERIFICATION_PUNCH_CARDS.READ_PENDING_VERIFICATION_PUNCH_CARDS,
                        ]) && (
                          <MenuItem value={ROUTES.PUNCHCARDS_PENDING_VERIFICATION}>
                            Business Hour Verifications
                          </MenuItem>
                        )}
                        {checkRoutePermissions([EXPENSE_TYPES.READ_EXPENSE_TYPES]) && (
                          <MenuItem value={ROUTES.EXPENSE_TYPES}>Expense Types</MenuItem>
                        )}
                        {checkRoutePermissions([INVOICES.READ_INVOICES]) && (
                          <MenuItem value={ROUTES.INVOICES_LIST}>Invoices</MenuItem>
                        )}
                        {checkRoutePermissions([
                          PUNCH_CARDS.READ_PUNCH_CARDS,
                          PUNCH_CARDS.UPDATE_PUNCH_CARDS,
                        ]) && (
                          <MenuItem value={ROUTES.PUNCHCARDS_PENDING_DELETION}>
                            Pending Deletion
                          </MenuItem>
                        )}
                        {checkRoutePermissions([TIMESHEETS.READ_TIMESHEETS]) && (
                          <MenuItem value={ROUTES.TIMESHEETS_LIST}>Timesheets</MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {checkRoutePermissions(
                      [
                        BUSINESS_USERS.READ_BUSINESS_USERS,
                        UPSHIFTER_USERS.READ_UPSHIFTER_USERS,
                        ADMIN_USERS.READ_ADMIN_USERS,
                      ],
                      'any'
                    ) && (
                      <SubMenu
                        icon={<FontAwesomeIcon icon={faUser} size='lg' />}
                        text={'Users'}
                        value={ROUTES.UPSHIFTERS_LIST}
                      >
                        {checkRoutePermissions([ADMIN_USERS.READ_ADMIN_USERS]) && (
                          <MenuItem value={ROUTES.ADMIN_USERS_LIST}>Admin Users</MenuItem>
                        )}
                        {checkRoutePermissions([BUSINESS_USERS.READ_BUSINESS_USERS]) && (
                          <MenuItem value={ROUTES.BUSINESS_USERS_LIST}>Business Users</MenuItem>
                        )}
                        {checkRoutePermissions([PENDING_SUSPENSION.READ_PENDING_SUSPENSION]) &&
                          FEATURE_TOGGLE_PENDING_TERMINATIONS && (
                            <MenuItem value={ROUTES.PENDING_SUSPENSION}>
                              <PendingSuspensionText>
                                Pending Permanent Suspension
                              </PendingSuspensionText>
                            </MenuItem>
                          )}
                        {checkRoutePermissions([UPSHIFTER_USERS.READ_UPSHIFTER_USERS]) && (
                          <MenuItem value={ROUTES.UPSHIFTERS_LIST}>Upshifters</MenuItem>
                        )}
                        {checkRoutePermissions([GROUPS.READ_GROUPS]) &&
                          FEATURE_TOGGLE_WORK_WITH_FRIENDS && (
                            <MenuItem value={ROUTES.GROUPS}>Work with Friends</MenuItem>
                          )}
                      </SubMenu>
                    )}
                    {checkRoutePermissions([BUSINESSES.READ_BUSINESSES]) && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={faBuilding} size='lg' />}
                        value={ROUTES.BUSINESSES_LIST}
                        text='Businesses'
                      >
                        Businesses
                      </MenuItem>
                    )}
                    {checkRoutePermissions(
                      [PROPERTIES.READ_PROPERTIES, PROPERTIES.CREATE_PROPERTIES],
                      'any'
                    ) && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={faCompass} size='lg' />}
                        text={'Locations'}
                        value={ROUTES.LOCATIONS_LIST}
                      >
                        Locations
                      </MenuItem>
                    )}
                    {checkRoutePermissions(
                      [CERTIFICATION_REQUESTS.READ_CERTIFICATION_REQUESTS],
                      'any'
                    ) && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={faFileSpreadsheet} size='lg' />}
                        text={'Certifications Requests'}
                        value={ROUTES.CERTIFICATION_REQUESTS}
                      >
                        Certification Requests
                      </MenuItem>
                    )}
                    {checkRoutePermissions(
                      [
                        REPORTS.AGING_REPORT_DOWNLOAD,
                        REPORTS.AGING_REPORT_SEND,
                        REPORTS.DOWNLOAD_MULTIPLE_ACKNOWLEDGED_DOCS,
                        REPORTS.EXPIRING_ONBOARD_DOCS_DOWNLOAD,
                        REPORTS.EXPIRING_ONBOARD_DOCS_SEND,
                        REPORTS.FAKE_PUNCHCARDS_DOWNLOAD,
                        REPORTS.FINANCE_AGING_DOWNLOAD,
                        REPORTS.GET_STATISTICS_BY_DATE,
                        REPORTS.GROWTH_DOWNLOAD,
                        REPORTS.INVOICES_DOWNLOAD,
                        REPORTS.MULTIPLE_PUNCHCARD_REPORT,
                        REPORTS.NO_SHOW_DOWNLOAD,
                        REPORTS.PAYMENT_APPLICATION_REPORT_DOWNLOAD,
                        REPORTS.PAYROLL_DOWNLOAD,
                        REPORTS.PAYROLL_SEND,
                        REPORTS.PROPERTIES_DOWNLOAD,
                        REPORTS.PROPERTIES_SEND,
                        REPORTS.SALES_TAXES_DOWNLOAD,
                        REPORTS.TERMINATED_AND_REINSTATED_UPSHIFTERS,
                        REPORTS.TIMESHEETS_DOWNLOAD,
                        REPORTS.WEEKLY_EMPLOYEE_REPORT_DOWNLOAD,
                        REPORTS.AM_SUCCESS_REPORT_DOWNLOAD,
                        REPORTS.AM_SUCCESS_REPORT_SEND,
                        REPORTS.UPSHIFTER_HOURS_REPORT,
                        REPORTS.ACCOUNT_BREAKDOWN_REPORT,
                        REPORTS.PROCESSED_TIMESHEET_REPORT,
                      ],
                      'any'
                    ) && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={faChartLine} size='lg' />}
                        value={ROUTES.REPORTS}
                        text='Reports'
                      >
                        Reports
                      </MenuItem>
                    )}
                    {FEATURE_TOGGLE_VENDOR_MANAGEMENT_SYSTEMS &&
                      checkRoutePermissions([
                        VENDOR_MANAGEMENT_SYSTEMS.READ_VENDOR_MANAGEMENT_SYSTEMS,
                      ]) && (
                        <MenuItemVms
                          icon={<FontAwesomeIcon icon={faCity} size='lg' />}
                          value={ROUTES.VENDOR_MANAGEMENT_SYSTEMS}
                          text='Vendor Management System'
                          overflow='visible'
                        >
                          Vendor Management System
                        </MenuItemVms>
                      )}
                    {checkRoutePermissions([
                      FEATURED_GIG_CAMPAIGNS.READ_FEATURED_GIG_CAMPAIGNS,
                    ]) && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={faBringFront} size='lg' />}
                        value={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_LIST}
                        text='Featured Shifts'
                      >
                        Featured Shifts
                      </MenuItem>
                    )}
                    {checkRoutePermissions([VACCINATION_FORMS.READ_VACCINATION_FORMS]) &&
                      FEATURE_TOGGLE_OSHA_ENABLED && (
                        <MenuItem
                          icon={<FontAwesomeIcon icon={faSyringe} size='lg' />}
                          text='Vaccination Forms'
                          value={ROUTES.VACCINATION_FORMS}
                        >
                          Vaccination Forms
                        </MenuItem>
                      )}
                    {checkRoutePermissions(
                      [
                        PAID_SICK_LEAVE_TEMPLATES.READ_PAID_SICK_LEAVE_TEMPLATES,
                        COUNTRIES.READ_COUNTRIES,
                        CERTIFICATES.READ_CERTIFICATES,
                        CERTIFICATE_ORGANIZATIONS.READ_CERTIFICATE_ORGANIZATIONS,
                        DEDUCTION_TYPES.READ_DEDUCTION_TYPES,
                        POSITION_CATEGORIZATION.READ_POSITION_CATEGORIZATION,
                        STRIKE_TYPES.READ_STRIKE_TYPES,
                        USER_ATTACHMENTS.READ_USER_ATTACHMENTS,
                        PROPERTY_TYPES.READ_PROPERTY_TYPES,
                        PROPERTY_CATEGORIES.READ_PROPERTY_CATEGORIES,
                        ONBOARDING_WORKFLOWS.READ_ONBOARDING_WORKFLOWS,
                      ],
                      'any'
                    ) && (
                      <SubMenu
                        icon={<FontAwesomeIcon icon={faGlobeAmericas} size='lg' />}
                        text={'Global Settings'}
                        value={ROUTES.SICK_LEAVE_TEMPLATES_LIST}
                      >
                        {/* TODO: Add permissions here */}
                        {checkRoutePermissions([COUNTRIES.READ_COUNTRIES]) && (
                          <MenuItem value={ROUTES.AREAS_COUNTRIES}>Areas</MenuItem>
                        )}

                        {checkRoutePermissions([
                          CERTIFICATE_ORGANIZATIONS.READ_CERTIFICATE_ORGANIZATIONS,
                        ]) && (
                          <MenuItem value={ROUTES.CERTIFICATE_ORGANIZATIONS_LIST}>
                            Certificate Organizations
                          </MenuItem>
                        )}

                        {checkRoutePermissions([CERTIFICATES.READ_CERTIFICATES]) && (
                          <MenuItem value={ROUTES.CERTIFICATES_LIST}>Certificates</MenuItem>
                        )}

                        {checkRoutePermissions([DEDUCTION_TYPES.READ_DEDUCTION_TYPES]) && (
                          <MenuItem value={ROUTES.DEDUCTION_TYPES_ADD}>Deduction Types</MenuItem>
                        )}

                        {checkRoutePermissions([DRUG_TESTING.EDIT_DRUG_TESTING]) && (
                          <MenuItem value={ROUTES.DRUG_TESTING_LIST}>Drug Testing</MenuItem>
                        )}

                        {checkRoutePermissions([
                          FOLLOW.READ_FOLLOW,
                          FOLLOW.UPDATE_FOLLOW,
                          FOLLOW.CREATE_FOLLOW,
                        ]) &&
                          FEATURE_TOGGLE_ADMIN_FOLLOW_SHIFTS && (
                            <MenuItem value={ROUTES.FOLLOW_MANAGEMENT}>Follow Management</MenuItem>
                          )}

                        {checkRoutePermissions([GAMIFICATION_SYSTEM.READ_GAMIFICATION_SYSTEM]) &&
                          FEATURE_TOGGLE_GAMIFICATION_SYSTEM && (
                            <MenuItem value={ROUTES.GAMIFICATION_SYSTEM}>
                              Gamification System
                            </MenuItem>
                          )}

                        {checkRoutePermissions([ONBOARDING_WORKFLOWS.READ_ONBOARDING_WORKFLOWS]) &&
                          FEATURE_TOGGLE_IN_APP_ONBOARDING && (
                            <MenuItem value={ROUTES.ONBOARDING_WORKFLOWS}>
                              In-App Onboarding
                            </MenuItem>
                          )}

                        {checkRoutePermissions([PROPERTY_CATEGORIES.READ_PROPERTY_CATEGORIES]) && (
                          <MenuItem value={ROUTES.LOCATIONS_LOCATION_CATEGORIES}>
                            Location Categories
                          </MenuItem>
                        )}

                        {checkRoutePermissions([PROPERTY_TYPES.READ_PROPERTY_TYPES]) && (
                          <MenuItem value={ROUTES.LOCATIONS_LOCATION_TYPES}>
                            Location Types
                          </MenuItem>
                        )}

                        {checkRoutePermissions([SUGGESTED_PAY_RATES.READ_SUGGESTED_PAY_RATES]) && (
                          <MenuItem value={ROUTES.PAY_RATE_SUGGESTIONS_LIST}>
                            Pay Rate Suggestions
                          </MenuItem>
                        )}

                        {checkRoutePermissions([
                          AVATAR_ANALYSIS_ERRORS.READ_AVATAR_ANALYSIS_ERRORS,
                        ]) &&
                          canAccessPhotoRecognitionPage() && (
                            <MenuItem value={ROUTES.PHOTO_RECOGNITION}>Photo Recognition</MenuItem>
                          )}

                        {checkRoutePermissions([POSITION_CATEGORIES.READ_POSITION_CATEGORIES]) && (
                          <MenuItem value={ROUTES.POSITION_CATEGORIES}>
                            Position Categories
                          </MenuItem>
                        )}

                        {checkRoutePermissions([
                          POSITION_CATEGORIZATION.READ_POSITION_CATEGORIZATION,
                        ]) && (
                          <MenuItem value={ROUTES.POSITION_CATEGORIZATION}>
                            Position Categorization
                          </MenuItem>
                        )}

                        {FEATURE_TOGGLE_SHIFT_IDENTIFIER &&
                          checkRoutePermissions([
                            SHIFT_IDENTIFIER_POLICIES.READ_SHIFT_IDENTIFIER_POLICIES,
                          ]) && (
                            <MenuItem value={ROUTES.SHIFT_IDENTIFIER_POLICIES}>
                              Shift Identifier Policies
                            </MenuItem>
                          )}

                        {checkRoutePermissions([
                          PAID_SICK_LEAVE_TEMPLATES.READ_PAID_SICK_LEAVE_TEMPLATES,
                        ]) && (
                          <MenuItem value={ROUTES.SICK_LEAVE_TEMPLATES_LIST}>
                            Sick Leave Templates
                          </MenuItem>
                        )}

                        {checkRoutePermissions([STRIKE_TYPES.READ_STRIKE_TYPES]) && (
                          <MenuItem value={ROUTES.STRIKE_TYPES_LIST}>Strike Types</MenuItem>
                        )}

                        {checkRoutePermissions([UNIVERSAL_TEMPLATES.READ_UNIVERSAL_TEMPLATES]) &&
                          FEATURE_TOGGLE_UNIVERSAL_TEMPLATES && (
                            <MenuItem value={ROUTES.UNIVERSAL_TEMPLATES}>
                              Universal Templates
                            </MenuItem>
                          )}

                        {checkRoutePermissions([ATTACHMENT_TITLES.READ_ATTACHMENT_TITLES]) && (
                          <MenuItem value={ROUTES.USER_ATTACHMENT_TYPES_LIST}>
                            User Attachment Types
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faCog} size='lg' />}
                      value={ROUTES.CHANGE_PASSWORD}
                      text='Change Password'
                      isAtTheBottom
                    >
                      Change Password
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faUserFriends} size='lg' />}
                      value={ROUTES.ONBOARDER_UPSHIFTERS_LIST}
                      text='View as Onboarder'
                      isAtTheBottom
                      isTargetBlank
                    >
                      View as Onboarder
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faUserCircle} size='lg' />}
                      value={ROUTES.LOGOUT}
                      text='Logout'
                      isAtTheBottom
                      isNavLink={false}
                    >
                      Logout
                    </MenuItem>
                  </SidebarMenu>
                </SidebarMenuProvider>
              </MobileMenuWrapper>
            </Content>
          )
        }}
      </Drawer>
    </>
  )
}

export default MenuMobile
