import { IAvatarAnalysisError, IGetAvatarAnalysisErrorsParams } from 'models/AvatarAnalysisError'
import { IPayload } from 'models/Global'
import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'

export class AvatarAnalysisErrorsApi {
  constructor(private axios: AxiosService) {}

  getAvatarAnalysisErrors = async (params?: IGetAvatarAnalysisErrorsParams) => {
    const response = await this.axios.get<IPayload<IAvatarAnalysisError[]>>(
      Endpoints.getAvatarAnalysisErrors,
      params
    )
    return response
  }

  getAvatarAnalysisError = async (id: number) => {
    const response = await this.axios.get<IPayload<IAvatarAnalysisError>>(
      Endpoints.getAvatarAnalysisError(id)
    )
    return response
  }
}
