import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { accruedSickHoursReducer } from 'data/AccruedSickHour/reducer'
import { acknowledgedDocumentsReducer } from 'data/AcknowledgedDocument/reducer'
import { activeShiftsReducer } from 'data/ActiveShifts/reducer'
import { punchCardActivityLogsReducer } from 'data/ActivityLogs'
import { blockHistoryReducer } from 'data/ActivityLogs/BlockHistory/reducer'
import { adminUserReducer } from 'data/AdminUser/reducer'
import { AllBusinessAutoAcceptCertificatesReducer } from 'data/AllBusinessAutoAcceptCertificates/reducer'
import { attachmentsReducer } from 'data/Attachment/reducer'
import { attachmentTitlesReducer } from 'data/AttachmentTitle/reducer'
import { authReducer } from 'data/Auth/reducer'
import { bulkEditShiftsReducer } from 'data/BulkEditShifts/reducer'
import { businessEntitiesReducer } from 'data/BusinessEntities/reducer'
import { businessEntityReducer } from 'data/BusinessEntity/reducer'
import { businessEntityAssignedCertificatesReducer } from 'data/BusinessEntityAssignedCertificates/reducer'
import { businessEntityUnassignedCertificatesReducer } from 'data/BusinessEntityUnassignedCertificates/reducer'
import { businessEntityUsersReducer } from 'data/BusinessEntityUsers/reducer'
import { certificateOrganizationsReducer } from 'data/CertificateOrganizations/reducer'
import { certificatesReducer } from 'data/Certificates/reducer'
import { citiesReducer } from 'data/City/reducer'
import { countriesReducer } from 'data/Country/reducer'
import { countiesReducer } from 'data/County/reducer'
import { creditsReducer } from 'data/Credits/reducer'
import { deductiontypesReducer } from 'data/DeductionTypes/reducer'
import { documentsReducer } from 'data/Documents/reducer'
import { expenseTypesReducer } from 'data/ExpenseType/reducer'
import { expenseTypeTaxesReducer } from 'data/ExpenseTypeTax/reducer'
import { FeaturedShiftCampaignReducer } from 'data/FeaturedShiftCampaign/reducer'
import { FeaturedShiftCampaignsReducer } from 'data/FeaturedShiftCampaigns/reducer'
import { FeaturedShiftCampaignAllShiftsReducer } from 'data/FeaturedShiftsCampaignAllShifts/reducer'
import { FeaturedShiftCampaignCreateSelectedShiftsReducer } from 'data/FeaturedShiftsCampaignCreateSelectedShifts/reducer'
import { FeaturedShiftCampaignCreateShiftsReducer } from 'data/FeaturedShiftsCampaignCreateShifts/reducer'
import { FeaturedShiftCampaignShiftsReducer } from 'data/FeaturedShiftsCampaignShifts/reducer'
import { followManagementBusinessEntitiesReducer } from 'data/FollowManagementBusinessEntities/reducer'
import { followManagementPropertiesReducer } from 'data/FollowManagementProperties/reducer'
import { followManagementShiftsReducer } from 'data/FollowManagementShifts/reducer'
import { gigTypesReducer } from 'data/GigTypes/reducer'
import { industriesReducer } from 'data/Industry/reducer'
import { invoiceReducer } from 'data/Invoice/reducer'
import { invoiceGroupsReducer } from 'data/InvoiceGroup/reducer'
import { invoiceGroupPropertiesReducer } from 'data/InvoiceGroupProperties/reducer'
import { invoicesReducer } from 'data/Invoices/reducer'
import { loadingsReducer } from 'data/Loadings/reducer'
import { netOptionsReducer } from 'data/NetOptions/reducer'
import { notesReducer } from 'data/Note/reducer'
import { notificationPreferencesReducer } from 'data/NotificationPreference/reducer'
import { onboardingWorkflowsReducer } from 'data/OnboardingWorkflows/reducer'
import { pendingDeletionPunchCardsReducer } from 'data/PendingDeletionPunchCards/reducer'
import { pendingTimetrackersReducer } from 'data/PendingTimetrackers/reducer'
import { propertiesReducer } from 'data/Properties/reducer'
import { propertyReducer } from 'data/Property/reducer'
import { PropertyCategoriesReducer } from 'data/PropertyCategories/reducer'
import { propertyTypesReducer } from 'data/PropertyTypes/reducer'
import { punchCardsReducer } from 'data/PunchCard/reducer'
import { regionsReducer } from 'data/Region/reducer'
import { reviewsReducer } from 'data/Reviews/reducer'
import { selectedShiftReducer } from 'data/SelectedShift/reducer'
import { selectedShiftAcceptedApplicationsReducer } from 'data/SelectedShiftAcceptedApplications/reducer'
import { selectedShiftApplicationLoadingsReducer } from 'data/SelectedShiftApplicationLoadings/reducer'
import { SelectedShiftBusinessEntityReducer } from 'data/SelectedShiftBusinessEntity/reducer'
import { SelectedShiftGigAutoAcceptCertificatesReducer } from 'data/SelectedShiftGigAutoAcceptCertificates/reducer'
import { selectedShiftGigDaysReducer } from 'data/SelectedShiftGigDays/reducer'
import { selectedShiftPendingApplicationsReducer } from 'data/SelectedShiftPendingApplications/reducer'
import { SelectedShiftPropertyReducer } from 'data/SelectedShiftProperty/reducer'
import { shiftsReducer } from 'data/Shift/reducer'
import { shiftIdentifierPoliciesReducer } from 'data/ShiftIdentifierPolicies/reducer'
import { shiftIdentifierPolicyReducer } from 'data/ShiftIdentifierPolicy/reducer'
import { sickLeaveTemplatesReducer } from 'data/SickLeaveTemplate/reducer'
import { statesReducer } from 'data/State/reducer'
import { strikeTypesReducer } from 'data/StrikeType/reducer'
import { suggestedPayRatesReducer } from 'data/SuggestedPayRate/reducer'
import { templatesReducer } from 'data/Templates/reducer'
import { timesheetsReducer } from 'data/Timesheet/reducer'
import { timesheetHotkeysReducer } from 'data/TimesheetHotkeys/reducer'
import { timesheetReducer } from 'data/Timesheets/reducer'
import { timetrackersReducer } from 'data/Timetracker/reducer'
import { universalTemplatesReducer } from 'data/UniversalTemplate/reducer'
import { upshifterReducer } from 'data/Upshifter/reducer'
import { userReducer } from 'data/User/reducer'
import { usersReducer } from 'data/Users/reducer'
import { userSickHourStatsReducer } from 'data/UserSickHourStats/reducer'
import { vaccinationFormsReducer } from 'data/VaccinationForms/reducer'
import { vaccinationTypesReducer } from 'data/VaccinationTypes/reducer'
import { GroupsReducer } from 'data/Groups/reducer'
import { GroupReducer } from 'data/Group/reducer'
import { challengesReducer } from 'data/Challenges/reducer'
import { challengeReducer } from 'data/Challenge/reducer'
import { positionCategoriesReducer } from 'data/PositionCategories/reducer'
import { achievementReducer } from 'data/Achievement/reducer'
import { achievementsReducer } from 'data/Achievements/reducer'
import { gigTypeExperiencesReducer } from 'data/GigTypeExperiences/reducer'
import { vendorManagementSystemsReducer } from 'data/VendorManagementSystems/reducer'
import { inviteApplicantsReducer } from 'data/InviteApplicants/reducer'
import { upshifterExperiencesReducer } from 'data/UpshifterExperience/reducer'
import { achDebitAuthorizationFlowReducer } from 'data/AchDebitAuthorizationFlow/reducer'
import { certificationRequestsReducer } from 'data/CertificationRequests/reducer'
import { certificationRequestReviewFlowReducer } from 'data/CertificationRequestReviewFlow/reducer'
import { avatarAnalysisErrorsReducer } from 'data/AvatarAnalysisErrors/reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  activeShifts: activeShiftsReducer,
  punchCards: punchCardsReducer,
  notes: notesReducer,
  businessEntities: businessEntitiesReducer,
  businessEntity: businessEntityReducer,
  businessEntityUsers: businessEntityUsersReducer,
  attachments: attachmentsReducer,
  invoices: invoicesReducer,
  invoice: invoiceReducer,
  documents: documentsReducer,
  acknowledgedDocuments: acknowledgedDocumentsReducer,
  users: usersReducer,
  user: userReducer,
  upshifter: upshifterReducer,
  userSickHourStats: userSickHourStatsReducer,
  timesheets: timesheetsReducer,
  timesheet: timesheetReducer,
  regions: regionsReducer,
  cities: citiesReducer,
  countries: countriesReducer,
  counties: countiesReducer,
  states: statesReducer,
  accruedSickHours: accruedSickHoursReducer,
  sickLeaveTemplates: sickLeaveTemplatesReducer,
  templates: templatesReducer,
  strikeTypes: strikeTypesReducer,
  deductionTypes: deductiontypesReducer,
  properties: propertiesReducer,
  property: propertyReducer,
  invoiceGroups: invoiceGroupsReducer,
  attachmentTitle: attachmentTitlesReducer,
  invoiceGroupProperties: invoiceGroupPropertiesReducer,
  businessEntityUnassignedCertificates: businessEntityUnassignedCertificatesReducer,
  businessEntityAssignedCertificates: businessEntityAssignedCertificatesReducer,
  certificates: certificatesReducer,
  certificateOrganizations: certificateOrganizationsReducer,
  groups: GroupsReducer,
  group: GroupReducer,
  gigTypes: gigTypesReducer,
  propertyCategories: PropertyCategoriesReducer,
  propertyTypes: propertyTypesReducer,
  shifts: shiftsReducer,
  featuredShiftCampaigns: FeaturedShiftCampaignsReducer,
  featuredShiftCampaign: FeaturedShiftCampaignReducer,
  featuredShiftCampaignShifts: FeaturedShiftCampaignShiftsReducer,
  featuredShiftCampaignAllShifts: FeaturedShiftCampaignAllShiftsReducer,
  featuredShiftCampaignCreateShifts: FeaturedShiftCampaignCreateShiftsReducer,
  featuredShiftCampaignCreateSelectedShifts: FeaturedShiftCampaignCreateSelectedShiftsReducer,
  suggestedPayRates: suggestedPayRatesReducer,
  selectedShift: selectedShiftReducer,
  selectedShiftPendingApplications: selectedShiftPendingApplicationsReducer,
  selectedShiftAcceptedApplications: selectedShiftAcceptedApplicationsReducer,
  selectedShiftGigDays: selectedShiftGigDaysReducer,
  selectedShiftApplicationLoadings: selectedShiftApplicationLoadingsReducer,
  punchCardActivityLogs: punchCardActivityLogsReducer,
  reviews: reviewsReducer,
  netOptions: netOptionsReducer,
  vaccinationForms: vaccinationFormsReducer,
  vaccinationTypes: vaccinationTypesReducer,
  selectedShiftProperty: SelectedShiftPropertyReducer,
  selectedShiftBusinessEntity: SelectedShiftBusinessEntityReducer,
  businessAutoAcceptCertificates: AllBusinessAutoAcceptCertificatesReducer,
  selectedShiftGigAutoAcceptCertificates: SelectedShiftGigAutoAcceptCertificatesReducer,
  credits: creditsReducer,
  pendingDeletionPunchCards: pendingDeletionPunchCardsReducer,
  bulkEditShifts: bulkEditShiftsReducer,
  adminUser: adminUserReducer,
  expenseTypes: expenseTypesReducer,
  expenseTypeTaxes: expenseTypeTaxesReducer,
  timetrackers: timetrackersReducer,
  pendingTimetrackers: pendingTimetrackersReducer,
  loadings: loadingsReducer,
  followManagementBusinessEntities: followManagementBusinessEntitiesReducer,
  followManagementProperties: followManagementPropertiesReducer,
  followManagementShifts: followManagementShiftsReducer,
  blockHistory: blockHistoryReducer,
  shiftIdentifierPolicies: shiftIdentifierPoliciesReducer,
  shiftIdentifierPolicy: shiftIdentifierPolicyReducer,
  onboardingWorkflows: onboardingWorkflowsReducer,
  timesheetHotkeys: timesheetHotkeysReducer,
  universalTemplates: universalTemplatesReducer,
  industries: industriesReducer,
  notificationPreferences: notificationPreferencesReducer,
  challenges: challengesReducer,
  challenge: challengeReducer,
  positionCategories: positionCategoriesReducer,
  achievement: achievementReducer,
  achievements: achievementsReducer,
  gigTypeExperiences: gigTypeExperiencesReducer,
  vendorManagementSystems: vendorManagementSystemsReducer,
  inviteApplicants: inviteApplicantsReducer,
  upshifterExperiences: upshifterExperiencesReducer,
  achDebitAuthorizationFlow: achDebitAuthorizationFlowReducer,
  certificationRequests: certificationRequestsReducer,
  certificationRequestReviewFlow: certificationRequestReviewFlowReducer,
  avatarAnalysisErrors: avatarAnalysisErrorsReducer,
})

export type AppState = ReturnType<typeof rootReducer>

// When we need to add middleware we just add in the list
const middlewares = [thunkMiddleware]
const middlewareEnhancer = applyMiddleware(...middlewares)

const store = createStore(rootReducer, composeWithDevTools(middlewareEnhancer))

export default store
